<div class="dropdown">
  <button class="dropdown-toggle" (click)="toggleDropdown()">
    {{ selectedOption || "Select an option" }}
  </button>
  <ul *ngIf="isOpen" class="dropdown-menu">
    <li
      *ngFor="let option of options"
      (click)="selectOption(option)"
      [class.selected]="option === selectedOption"
    >
      {{ option }}
    </li>
  </ul>
</div>

<!-- <app-dropdown
        [options]="['Option 1', 'Option 2', 'Option 3']"
        (optionSelected)="onOptionSelected($event)"
      >
      </app-dropdown> -->
