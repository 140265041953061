<div class="table-menu">
  <button class="menu-button" [matMenuTriggerFor]="menu">
    <app-svg-icon [path]="'assets/icons/more_menu_icon.svg'"></app-svg-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let option of options"
      (click)="onOptionSelect(option)"
    >
      <span>{{ option.label }}</span>
    </button>
  </mat-menu>
</div>
