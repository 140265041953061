import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoginRequest } from '../models/login-request.interface';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';

const loginEndPoint = 'v1/verification/login';
const logoutEndPoint = 'v1/logout';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginUrl = `${environment.apiBaseUrl}/${loginEndPoint}`;
  private logoutUrl = `${environment.apiBaseUrl}/${logoutEndPoint}`;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  login(data: LoginRequest): Observable<any> {
    return this.http
      .post(this.loginUrl, data, {
        withCredentials: true,
      })
      .pipe(
        tap(() => {
          this.cookieService.set('IsAuthenticated', 'YES', {
            secure: true,
            sameSite: 'Strict',
            path: '/',
          });
        })
      );
  }

  // Function to check if the user is logged in based on the presence of a cookie
  isLoggedIn(): boolean {
    return this.cookieService.check('IsAuthenticated');
  }

  // Function to log out the user
  logout(): void {
    this.loadingService.show();
    this.http.post(this.logoutUrl, {}).subscribe({
      next: (res) => {
        console.log('----Logout Reponse----');
        console.log(res);
        this.cleanUpPostLogout();
        this.loadingService.hide();
      },
      error: (err) => {
        console.log('[Logout Error]');
        console.error(err);
        this.cleanUpPostLogout();
        this.loadingService.hide();
      },
    });
    // do other cleanup tasks here
  }

  cleanUpPostLogout() {
    // Delete client-side cookies
    this.cookieService.deleteAll('/');
    setTimeout(() => {
      this.router.navigate([`/`]);
    });
  }
}
