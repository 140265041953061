<div class="user-dialog">
  <form [formGroup]="userForm" (ngSubmit)="submitUserForm()">
    <div class="form-row">
      <div class="form-group">
        <label for="name" class="required_field_label">Name</label>
        <input
          id="name"
          name="name"
          formControlName="name"
          type="text"
          placeholder="Enter name"
          required
          autocomplete="name"
          [ngClass]="{
            form_error:
              userForm.get('name')?.invalid &&
              userForm.get('name')?.touched &&
              userForm.get('name')?.errors
          }"
        />
        <div
          class="form_validation_error"
          *ngIf="userForm.get('name')?.invalid && userForm.get('name')?.touched"
        >
          <small *ngIf="userForm.get('name')?.errors?.['required']"
            >Name is required</small
          >
          <small *ngIf="userForm.get('name')?.errors?.['name']"
            >Invalid email format</small
          >
        </div>
      </div>

      <div class="form-group">
        <label for="email" class="required_field_label">Email</label>
        <input
          id="email"
          name="email"
          formControlName="email"
          type="email"
          placeholder="Enter your email"
          required
          autocomplete="email"
          [ngClass]="{
            form_error:
              userForm.get('email')?.invalid &&
              userForm.get('email')?.touched &&
              userForm.get('email')?.errors
          }"
        />
        <div
          class="form_validation_error"
          *ngIf="
            userForm.get('email')?.invalid && userForm.get('email')?.touched
          "
        >
          <small *ngIf="userForm.get('email')?.errors?.['required']"
            >Email is required</small
          >
          <small *ngIf="userForm.get('email')?.errors?.['email']"
            >Invalid email format</small
          >
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label for="newPassword" class="required_field_label"
          >Set Password</label
        >
        <input
          id="newPassword"
          name="newPassword"
          formControlName="newPassword"
          type="password"
          placeholder="Set a new password"
          required
          [ngClass]="{
            form_error:
              userForm.get('newPassword')?.invalid &&
              userForm.get('newPassword')?.touched &&
              userForm.get('newPassword')?.errors
          }"
        />

        <div
          *ngIf="
            userForm.get('newPassword')?.invalid &&
            userForm.get('newPassword')?.touched &&
            userForm.get('newPassword')?.errors
          "
          class="form_validation_error"
        >
          <ng-container
            *ngIf="userForm.get('newPassword')?.errors?.['required']"
          >
            <small>Password is required</small> <br />
          </ng-container>
        </div>
      </div>

      <div class="form-group">
        <label for="confirmPassword" class="required_field_label"
          >Confirm Password</label
        >
        <input
          id="confirmPassword"
          name="confirmPassword"
          formControlName="confirmPassword"
          type="password"
          placeholder="Re-enter password again"
          required
          [ngClass]="{
            form_error:
              userForm.get('confirmPassword')?.touched &&
              (userForm.get('confirmPassword')?.errors ||
                userForm.hasError('passwordMismatch'))
          }"
        />
        <div
          class="form_validation_error"
          *ngIf="
            userForm.get('confirmPassword')?.touched &&
            (userForm.get('confirmPassword')?.errors ||
              userForm.hasError('passwordMismatch'))
          "
        >
          <ng-container
            *ngIf="userForm.get('confirmPassword')?.errors?.['required']"
          >
            <small>Confirm password is required</small><br />
          </ng-container>

          <ng-container
            *ngIf="
            !userForm.get('confirmPassword')?.errors?.['required'] && userForm.hasError('passwordMismatch')
            "
          >
            <small> Passwords do not match.</small><br />
          </ng-container>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group">
        <label for="phone" class="required_field_label">Phone Number</label>
        <input
          id="phone"
          name="phone"
          formControlName="phone"
          type="tel"
          placeholder="Enter your phone number"
          required
          autocomplete="new-phone"
          maxLength="10"
          [ngClass]="{
            form_error:
              userForm.get('phone')?.invalid &&
              userForm.get('phone')?.touched &&
              userForm.get('phone')?.errors
          }"
        />
        <div
          class="form_validation_error"
          *ngIf="
            userForm.get('phone')?.invalid && userForm.get('phone')?.touched
          "
        >
          <small *ngIf="userForm.get('phone')?.errors?.['required']"
            >Phone number is required</small
          >
          <small *ngIf="userForm.get('phone')?.errors?.['pattern']"
            >Enter 10 digits</small
          >
        </div>
      </div>

      <div class="form-group">
        <label for="emailMode" class="required_field_label">Login Mode</label>
        <div class="radio_btns">
          <label>
            <input
              type="radio"
              formControlName="loginMode"
              value="email"
              id="emailMode"
            />
            <span>Email / Phone</span>
          </label>
          <label>
            <input
              type="radio"
              formControlName="loginMode"
              value="phone"
              id="phoneMode"
            />
            <span>Phone only</span>
          </label>
        </div>
        <div
          class="form_validation_error"
          *ngIf="
            userForm.controls['loginMode'].invalid &&
            userForm.controls['loginMode'].touched
          "
        >
          <small class="error">Select login mode</small>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="full-width">
        <label>Choose Role</label>

        <mat-form-field appearance="outline" class="custom-select-field">
          <mat-select
            id="roles"
            formControlName="roles"
            placeholder="Select roles"
            multiple
            disableOptionCentering
            [compareWith]="compareRoles"
          >
            <mat-select-trigger
              *ngIf="userForm.get('roles')?.value?.length > 0"
            >
              {{ userForm.get("roles")?.value?.length }} selected
            </mat-select-trigger>
            <mat-option *ngFor="let role of roles" [value]="role" disableRipple>
              {{ role.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container *ngIf="userForm.get('roles')?.value?.length > 0">
          <div class="role_chips">
            <div class="chip" *ngFor="let role of userForm.get('roles')?.value">
              <span> {{ role.name }}</span>
              <app-svg-icon
                [path]="'assets/icons/close.svg'"
                (click)="removeChip(role)"
              ></app-svg-icon>
            </div>
          </div>
        </ng-container>

        <!-- <app-select
          id="roles"
          [displayKey]="'name'"
          [options]="roles"
          [multiple]="true"
          placeholder="Select roles"
          (selectionChange)="onSelectionChange($event)"
          formControlName="roles"
        ></app-select> -->
        <!-- [(preSelectedOptions)]="preSelectedOptions" -->

        <!-- <div
          *ngIf="
            userForm.controls['roles'].invalid &&
            userForm.controls['roles'].touched
          "
        >
          <small class="form_validation_error">Select at least one role</small>
        </div> -->
      </div>
    </div>

    <div class="form-row make_admin_row">
      <label for="isAdmin">
        <input id="isAdmin" formControlName="isAdmin" type="checkbox" />
        <span>Make Admin</span>
      </label>
    </div>

    <div class="centered_flex_container dialog_actions">
      <button type="button" class="secondary_button" (click)="close()">
        Cancel
      </button>
      <button type="submit" class="primary_button">
        @if(userEditingEnabled){ Update }@else { Add }
      </button>
    </div>
  </form>
</div>
