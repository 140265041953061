import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../modules/material/material.module';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MaterialModule,
    CommonModule,
    SvgIconComponent,
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
})
export class SelectComponent {
  @Input() displayKey: string = '';
  @Input() options: any[] = [];
  @Input() multiple: boolean = false;
  @Input() placeholder: string = 'Select an option';
  // @Input() preSelectedOptions: any[] = [];

  @Output() selectionChange = new EventEmitter<string | string[]>();

  selectControl = new FormControl();

  constructor() {
    this.selectControl.valueChanges.subscribe((value) => {
      this.selectionChange.emit(value);
    });
  }

  ngOnInit() {
    // if (this.preSelectedOptions && this.preSelectedOptions.length > 0) {
    //   this.selectControl.setValue(this.preSelectedOptions); // Initialize with preselected options
    // }
  }

  removeChip(option: string) {
    const value = this.selectControl.value as string[];
    const index = value.indexOf(option);
    if (index >= 0) {
      value.splice(index, 1);
      this.selectControl.setValue(value);
      this.selectionChange.emit(value);
    }
  }

  get selectedOptions(): string[] {
    return this.selectControl.value || [];
  }

  get selectedOptionsCount(): number {
    return this.selectControl.value?.length || 0;
  }
}
