import { AbstractControl, ValidatorFn } from '@angular/forms';

export function atLeastOneCheckboxCheckedValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const customerTypes = control.value;
    const atLeastOneChecked = customerTypes.some((checked: boolean) => checked);

    return atLeastOneChecked ? null : { atLeastOneCheckboxChecked: true };
  };
}
