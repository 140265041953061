import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoadingService } from '../../services/loading.service';

interface LoaderConfig {
  isLoading: boolean;
  type: 'full' | 'small';
  dismissible?: boolean;
}

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  @Input() config: LoaderConfig = {
    isLoading: false,
    type: 'full',
    dismissible: false,
  };

  constructor(private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.loading$.subscribe((config) => {
      this.config = config;
    });
  }

  dismiss() {
    this.loadingService.hide();
  }
}
