<div class="tos-table-template">
  <div class="table-stats">
    <div class="left">
      <div class="total-users">
        Total Customers: {{ customerStats.total_customers }}
      </div>
      <div class="active-users">
        Active: {{ customerStats.active_customers }}
      </div>
      <div class="inactive-users">
        Inactive: {{ customerStats.inactive_customers }}
      </div>
    </div>
    <div class="right">
      <div
        class="search-component-container"
        [class.search-input-focus]="focusSearch"
      >
        <input
          type="text"
          name="search_users"
          id="search_users"
          [(ngModel)]="searchQuery"
          placeholder="Search by name"
          (focus)="focusSearch = true"
          (focusout)="focusSearch = false"
          (keyup.enter)="customerTableSearch()"
          (keyup)="handleCustomerTableSearch($event)"
        />
        <button (click)="customerTableSearch()">
          <app-svg-icon [path]="'assets/icons/search_icon.svg'"></app-svg-icon>
        </button>
      </div>
      <button
        class="secondary_button"
        [ngClass]="{
          filter_is_applied:
            !searchQuery && customerMasterListRequest.where ? true : false
        }"
        (click)="filter()"
      >
        <app-svg-icon [path]="'assets/icons/filter.svg'"></app-svg-icon>
        Filter
      </button>
      <button class="primary_button" (click)="addNewUser()">
        <app-svg-icon [path]="'assets/icons/add_icon.svg'"></app-svg-icon>
        Add New
      </button>
    </div>
  </div>

  <div class="table-container">
    <ng-container *ngIf="paginatedData?.length > 0">
      <table>
        <thead>
          <tr>
            <th class="cb_th select-all-cb">
              <input
                type="checkbox"
                id="select_all_users"
                [checked]="isAllSelected()"
                (change)="toggleAllSelections($event.target.checked)"
              />
            </th>
            <th class="sl_no_th">Sl No.</th>
            <th
              class="name_th sortable"
              [ngClass]="{ sorted: sortColumn === 'name' }"
              (click)="sortData('name')"
            >
              <div class="icon_cover">
                Customer Name
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>
            <th
              class="code_th sortable"
              [ngClass]="{ sorted: sortColumn === 'code' }"
              (click)="sortData('code')"
            >
              <div class="icon_cover">
                Customer Code
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>

            <th class="customerType_th">Customer Type</th>

            <th
              class="status_th sortable"
              [ngClass]="{ sorted: sortColumn === 'active' }"
              (click)="sortData('active')"
            >
              <div class="icon_cover">
                Status
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>

            <!-- <th
                class="created_date_th sortable"
                [ngClass]="{ sorted: sortColumn === 'createdAt' }"
                (click)="sortData('createdAt')"
              >
                <div class="icon_cover">
                  Created date
                  <app-svg-icon
                    [path]="'assets/icons/sort_icon.svg'"
                  ></app-svg-icon>
                </div>
              </th> -->

            <th
              class="modified_date_th sortable"
              [ngClass]="{ sorted: sortColumn === 'modifiedAt' }"
              (click)="sortData('modifiedAt')"
            >
              <div class="icon_cover">
                Modified date
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>

            <!-- <th
                class="createdBy_th sortable"
                [ngClass]="{ sorted: sortColumn === 'createdBy' }"
                (click)="sortData('createdBy')"
              >
                <div class="icon_cover">
                  Created By
                  <app-svg-icon
                    [path]="'assets/icons/sort_icon.svg'"
                  ></app-svg-icon>
                </div>
              </th> -->
            <th
              class="modifiedBy_th sortable"
              [ngClass]="{ sorted: sortColumn === 'modifiedBy' }"
              (click)="sortData('modifiedBy')"
            >
              <div class="icon_cover">
                Modified By
                <app-svg-icon
                  [path]="'assets/icons/sort_icon.svg'"
                ></app-svg-icon>
              </div>
            </th>

            <th class="table_menu_th">
              <!-- <div class="icon_cover plus_icon">
                    <app-svg-icon
                      [path]="'assets/icons/plus_icon.svg'"
                    ></app-svg-icon>
                  </div> -->
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let user of paginatedData; let i = index"
            [ngClass]="isSelected(user) ? 'selected_row' : ''"
          >
            <td class="cb_td row-select-cb">
              <input
                type="checkbox"
                [id]="'user_sl_no_' + i + 1"
                [checked]="isSelected(user)"
                (change)="toggleSelection(user)"
              />
            </td>
            <td class="sl_no_td">
              <!-- {{ i + 1 }} -->
              {{ getSerialNumber(i) }}
            </td>
            <td class="name_td" [title]="user.name">
              {{ user.name }}
            </td>
            <td class="code_td" [title]="user.code">
              {{ user.code }}
            </td>

            <td class="customerType_td">
              @if (user.customerType && user.customerType.length > 0) {
              <div class="role_container">
                <div class="first_role">{{ user.customerType[0] }}</div>
                <div
                  class="role_count"
                  [appTooltip]="user.customerType.slice(1)"
                  [keyToPick]="'name'"
                  position="bottom"
                  *ngIf="user.customerType.length > 1"
                >
                  + {{ user.customerType.length - 1 }}
                </div>
              </div>
              }@else { N/A }
            </td>
            <td class="status_td">
              <div
                class="icon_cover"
                [ngClass]="user.active ? 'active' : 'inactive'"
              >
                <app-svg-icon
                  [path]="'assets/icons/circle_icon.svg'"
                ></app-svg-icon>
                {{ user.active ? "Active" : "Inactive" }}
              </div>
            </td>
            <!-- <td class="created_date_td">
                {{ user.createdAt | date : "dd/MM/yy hh:mm a" }}
              </td> -->
            <td class="modified_date_td">
              {{ user.modifiedAt | date : "dd/MM/yy hh:mm a" }}
            </td>
            <!-- <td class="createdBy_td">
                {{ user.createdBy || "N/A" }}
              </td> -->
            <td class="modifiedBy_td">
              {{ user.modifiedBy || "N/A" }}
            </td>
            <td class="table_menu_td">
              <div class="menu_component">
                <app-table-menu
                  [options]="menuOptions"
                  [isOpen]="openMenuIndex === i"
                  (toggle)="handleMenuToggle(i)"
                  (optionSelected)="handleOptionSelected(user, $event)"
                  [tableRowData]="user"
                >
                </app-table-menu>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-container *ngIf="tableLoaded && paginatedData?.length === 0">
      <app-no-data-found></app-no-data-found>
    </ng-container>
  </div>
  <div class="action_bar">
    <div class="bulk_actions">
      <ng-container *ngIf="selectedItems.length > 0">
        <div class="selection_count">
          <div class="count">{{ selectedItems.length }} selected</div>
          <button
            class="table_select_all_button"
            *ngIf="customerStats.total_users"
            (click)="selectAll()"
            [disabled]="isAllSelected()"
          >
            @if(isAllSelected()){ Selected all users }@else { Select all ({{
              userStats.total_users
            }}
            users) }
          </button>
        </div>
        <div class="actions">
          <button
            class="secondary_button tick"
            (click)="bulkActionConfirmation('setActive')"
          >
            <app-svg-icon [path]="'assets/icons/tick.svg'"></app-svg-icon>
            Active
          </button>
          <button
            class="secondary_button close"
            (click)="bulkActionConfirmation('setInActive')"
          >
            <app-svg-icon [path]="'assets/icons/close.svg'"></app-svg-icon>
            Inactive
          </button>
        </div>
      </ng-container>
    </div>
    <div class="pagination">
      <span class="info">
        Showing
        {{
          currentTablePage === 1
            ? 1
            : currentTablePage * rowsPerPage + 1 - rowsPerPage
        }}
        to
        {{
          currentTablePage * rowsPerPage > userListData.totalCount
            ? userListData.totalCount
            : currentTablePage * rowsPerPage
        }}
        of {{ userListData.totalCount }} customers
      </span>
      <div class="buttons">
        <button
          class="previous_btn"
          (click)="previousPage()"
          [disabled]="currentTablePage === 1"
          title="Previous Page"
        >
          <app-svg-icon [path]="'assets/icons/left-arrow.svg'"></app-svg-icon>
        </button>

        <!-- <button *ngIf="getVisiblePages()[0] > 1" disabled>...</button>
  
          <button
            *ngFor="let page of getVisiblePages()"
            (click)="goToPage(page)"
            [class.active]="currentPage === page"
          >
            {{ page }}
          </button>
  
          <button
            *ngIf="getVisiblePages().slice(-1)[0] < totalTablePages"
            disabled
          >
            ...
          </button> -->

        <!-- <div class="buttons_scroll">
            <button
              *ngFor="
                let page of [].constructor(totalTablePages);
                let i = index
              "
              (click)="goToPage(i + 1)"
              [class.active]="currentPage === i + 1"
            >
              {{ i + 1 }}
            </button>
          </div> -->

        <ng-container *ngIf="totalTablePages">
          <button
            *ngFor="let page of [].constructor(totalTablePages); let i = index"
            (click)="goToPage(i + 1)"
            [class.active]="currentTablePage === i + 1"
          >
            {{ i + 1 }}
          </button>
        </ng-container>

        <button
          class="next_btn"
          (click)="nextPage()"
          [disabled]="currentTablePage === totalTablePages"
          title="Next Page"
        >
          <app-svg-icon [path]="'assets/icons/right-arrow.svg'"></app-svg-icon>
        </button>
      </div>
      <span class="info"> Per page:</span>
      <select
        id="rowsPerPage"
        class="row-options"
        #rowOptions
        (change)="updateRowsPerPage(rowOptions.value)"
      >
        <option
          class="option"
          *ngFor="let option of rowsPerPageOptions"
          [value]="option"
        >
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</div>
