<div class="table-container">
  <div class="table-settings">
    <label>
      Columns:
      <select [(ngModel)]="selectedColumns" multiple>
        <option *ngFor="let column of availableColumns" [value]="column">
          {{ column }}
        </option>
      </select>
    </label>
    <label>
      Rows per page:
      <select [(ngModel)]="rowsPerPage">
        <option *ngFor="let option of rowsPerPageOptions" [value]="option">
          {{ option }}
        </option>
      </select>
    </label>
  </div>

  <div class="table-wrapper">
    <table>
      <thead>
        <tr>
          <th class="sticky-column">
            <input type="checkbox" (change)="toggleSelectAll($event)" />
          </th>
          <th
            *ngFor="let column of selectedColumns; let i = index"
            [ngClass]="{ 'sticky-column': i < fixedColumnsCount }"
          >
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of paginatedRows; let i = index">
          <td class="sticky-column">
            <input type="checkbox" [(ngModel)]="row.selected" />
          </td>
          <td
            *ngFor="let column of selectedColumns; let i = index"
            [ngClass]="{ 'sticky-column': i < fixedColumnsCount }"
          >
            {{ row[column] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="pagination">
    <button (click)="previousPage()" [disabled]="currentPage === 1">
      Previous
    </button>
    <span
      *ngFor="let page of pages"
      [ngClass]="{ active: page === currentPage }"
      (click)="goToPage(page)"
    >
      {{ page }}
    </span>
    <button (click)="nextPage()" [disabled]="currentPage === totalPages">
      Next
    </button>
  </div>
</div>
