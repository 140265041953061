import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-table-box',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './table-box.component.html',
  styleUrl: './table-box.component.scss',
})
export class TableBoxComponent {
  @Input() data: any[] = [];
  @Input() columns: string[] = [];

  selectedColumns: string[] = [];
  availableColumns: string[] = [];
  rowsPerPageOptions: number[] = [5, 10, 20, 50];
  rowsPerPage: number = 10;
  currentPage: number = 1;

  get paginatedRows() {
    const start = (this.currentPage - 1) * this.rowsPerPage;
    return this.data.slice(start, start + this.rowsPerPage);
  }

  get totalPages() {
    return Math.ceil(this.data.length / this.rowsPerPage);
  }

  get pages() {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  ngOnInit() {
    this.availableColumns = [...this.columns];
    this.selectedColumns = [...this.columns];
  }

  toggleSelectAll(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    this.paginatedRows.forEach((row) => (row.selected = checked));
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  goToPage(page: number) {
    this.currentPage = page;
  }
}
