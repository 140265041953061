import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appTooltip]',
  standalone: true,
})
export class TooltipDirective {
  @Input('appTooltip') tooltipData!:
    | string
    | string[]
    | { [key: string]: any }[];
  @Input() position: 'top' | 'bottom' | 'left' | 'right' = 'top';
  @Input() keyToPick!: string;
  private tooltip!: HTMLElement;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mouseenter') onMouseEnter() {
    if (!this.tooltip) {
      this.createTooltip();
      this.setPosition();
      this.renderer.addClass(this.tooltip, 'tooltip-fade-in');
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private createTooltip() {
    this.tooltip = this.renderer.createElement('div');
    this.renderer.addClass(this.tooltip, 'custom-tooltip');

    const tooltipContent = this.formatTooltipContent(this.tooltipData);
    this.tooltip.innerHTML = tooltipContent;

    this.renderer.appendChild(document.body, this.tooltip);
  }

  private formatTooltipContent(
    data: string | string[] | { [key: string]: any }[]
  ): string {
    if (Array.isArray(data)) {
      if (data.length === 0) return 'No data available';

      if (typeof data[0] === 'string') {
        return data.map((item) => `<div>${item}</div>`).join('');
      } else if (typeof data[0] === 'object' && data[0][this.keyToPick]) {
        return data
          .map((item: any) => `<div>${item[this.keyToPick]}</div>`)
          .join('');
      } else {
        return 'Invalid data format';
      }
    } else if (typeof data === 'string') {
      return data;
    } else {
      return 'Invalid data type';
    }
  }

  private setPosition() {
    const hostPos = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos = this.tooltip.getBoundingClientRect();
    const scrollPos = window.scrollY || window.pageYOffset;

    let top: number;
    let left: number;

    switch (this.position) {
      case 'top':
        top = hostPos.top + scrollPos - tooltipPos.height - 8;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        break;
      case 'bottom':
        top = hostPos.bottom + scrollPos + 8;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        break;
      case 'left':
        top =
          hostPos.top + scrollPos + (hostPos.height - tooltipPos.height) / 2;
        left = hostPos.left - tooltipPos.width - 8;
        break;
      case 'right':
        top =
          hostPos.top + scrollPos + (hostPos.height - tooltipPos.height) / 2;
        left = hostPos.right + 8;
        break;
      default:
        top = hostPos.top + scrollPos - tooltipPos.height - 8;
        left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
        break;
    }

    this.renderer.setStyle(this.tooltip, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltip, 'left', `${left}px`);
  }

  private hideTooltip() {
    if (this.tooltip) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null!;
    }
  }
}
