import { Routes } from '@angular/router';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './layouts/private-layout/private-layout.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { ManageUsersComponent } from './components/admin/manage-users/manage-users.component';
import { SupportComponent } from './components/support/support.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { MasterManagementComponent } from './components/masters/master-management/master-management.component';
import { CustomerMasterComponent } from './components/masters/customer-master/customer-master.component';
import { ManageCustomerComponent } from './components/masters/customer-master/manage-customer/manage-customer.component';

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: PublicLayoutComponent,
    children: [{ path: '', component: LoginComponent }],
    canActivate: [LoginGuard],
  },
  {
    path: 'forgot-password',
    component: PublicLayoutComponent,
    children: [{ path: '', component: ForgotPasswordComponent }],
    canActivate: [LoginGuard],
  },
  {
    path: 'reset-password/:token',
    component: PublicLayoutComponent,
    children: [{ path: '', component: ResetPasswordComponent }],
    canActivate: [LoginGuard],
  },
  {
    path: 'dashboard',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: DashboardComponent }],
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/manage-users',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: ManageUsersComponent }],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/customers',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: MasterManagementComponent },
      { path: 'add-new', component: ManageCustomerComponent },
      { path: 'edit/:customerId', component: ManageCustomerComponent },
      { path: 'view/:customerId', component: ManageCustomerComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'support',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: SupportComponent }],
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: SettingsComponent }],
    canActivate: [AuthGuard],
  },
  {
    path: 'user-profile',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: UserProfileComponent }],
    canActivate: [AuthGuard],
  },

  // Wildcard route for a 404 page
  { path: '**', component: PageNotFoundComponent },
];
