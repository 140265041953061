import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private encryptionKey: string = environment.encryptionKey;

  constructor() {}

  enableEncryption: boolean = environment.enableLocalStorageEncryption;

  setItem(key: string, value: any): void {
    if (this.enableEncryption) {
      const encryptedValue = CryptoJS.AES.encrypt(
        JSON.stringify(value),
        this.encryptionKey
      ).toString();
      localStorage.setItem(key, encryptedValue);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    if (item) {
      if (this.enableEncryption) {
        const bytes = CryptoJS.AES.decrypt(item, this.encryptionKey);
        const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedValue) as T;
      } else {
        return JSON.parse(item) as T;
      }
    }
    return null;
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
