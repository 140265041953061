import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToasterComponent } from './components/toaster/toaster.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingService } from './services/loading.service';
import { NetworkStatusService } from './services/network-status.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToasterComponent, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'SmartTOS';
  online: boolean = navigator.onLine;
  connectionType: string = 'unknown';

  constructor(
    private loadingService: LoadingService,
    private networkStatusService: NetworkStatusService
  ) {}

  ngOnInit() {
    this.networkStatusService.isOnline.subscribe((status) => {
      this.online = status;
      this.showAlert(this.online);
    });

    this.networkStatusService.connectionType.subscribe((type) => {
      this.connectionType = type;
      if (this.connectionType === 'slow-2g' || this.connectionType === '2g') {
        console.log('Your connection is slow. You may experience delays.');
      }
    });
  }

  showAlert(isOnline: boolean) {
    if (isOnline) {
      console.log('Online');
    } else {
      console.log('You are offline. Please check your internet connection.');
    }
  }

  showFullLoader() {
    this.loadingService.show('full');
    setTimeout(() => {
      this.loadingService.hide();
    }, 3000);
  }

  showSmallLoader() {
    this.loadingService.show('small', true); // Dismissible small loader
    setTimeout(() => {
      this.loadingService.hide();
    }, 5000);
  }
}
