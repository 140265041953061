import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private onlineStatus$ = new BehaviorSubject<boolean>(navigator.onLine);
  private connectionType$ = new BehaviorSubject<string>('unknown');

  constructor() {
    const online$ = fromEvent(window, 'online').pipe(map(() => true));
    const offline$ = fromEvent(window, 'offline').pipe(map(() => false));

    merge(online$, offline$).subscribe((status) =>
      this.onlineStatus$.next(status)
    );

    if ('connection' in navigator) {
      const connection = (navigator as any).connection;
      this.connectionType$.next(connection.effectiveType);

      connection.addEventListener('change', () => {
        this.connectionType$.next(connection.effectiveType);
      });
    }
  }

  get isOnline(): Observable<boolean> {
    return this.onlineStatus$.asObservable();
  }

  get connectionType(): Observable<string> {
    return this.connectionType$.asObservable();
  }
}
