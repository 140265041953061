<div class="role-dialog">
  <form [formGroup]="roleForm" (ngSubmit)="submitForm()">
    <div class="form-row">
      <div class="form-group">
        <label for="name" class="required_field_label">Role Name</label>
        <input
          id="name"
          name="name"
          formControlName="name"
          type="text"
          placeholder="Enter role name"
          required
          autocomplete="role-name"
          [ngClass]="{
            form_error:
              roleForm.get('name')?.invalid &&
              roleForm.get('name')?.touched &&
              roleForm.get('name')?.errors
          }"
        />
        <div
          class="form_validation_error"
          *ngIf="roleForm.get('name')?.invalid && roleForm.get('name')?.touched"
        >
          <small *ngIf="roleForm.get('name')?.errors?.['required']"
            >Role Name is required</small
          >
        </div>
      </div>

      <div class="form-group">
        <label for="description">Description</label>
        <input
          id="description"
          name="description"
          formControlName="description"
          type="text"
          placeholder="Enter description"
          autocomplete="description"
        />
      </div>

      <!-- <div class="form-group">
        <label for="newPassword" class="required_field_label"
          >Preferred Yard</label
        >

        <select
          id="cfsId"
          formControlName="cfsId"
          placeholder="Select yard"
          required
          [ngClass]="{
            form_error:
              roleForm.get('cfsId')?.invalid &&
              roleForm.get('cfsId')?.touched &&
              roleForm.get('cfsId')?.errors
          }"
        >
          <option value="ICTPL, Nhava Sheva">ICTPL, Nhava Sheva</option>
          <option value="ICTPL, Mundra">ICTPL, Mundra</option>
          <option value="Chandra CFS">Chandra CFS</option>
          <option value="Batco Integrated Logistics">
            Batco Integrated Logistics
          </option>
        </select>

        <div
          *ngIf="
            roleForm.get('cfsId')?.invalid &&
            roleForm.get('cfsId')?.touched &&
            roleForm.get('cfsId')?.errors
          "
          class="form_validation_error"
        >
          <ng-container *ngIf="roleForm.get('cfsId')?.errors?.['required']">
            <small>Select preferred yard</small> <br />
          </ng-container>
        </div>
      </div> -->
    </div>

    <div class="centered_flex_container dialog_actions">
      <button type="button" class="secondary_button" (click)="close()">
        Cancel
      </button>
      <button type="submit" class="primary_button">
        @if(isEditingEnabled){ Update }@else { Add }
      </button>
    </div>
  </form>
</div>
