<div
  class="container full_page_container login_system_bkg_cover centered_flex_container"
>
  @if (!verificationEmailSent) {
  <div class="login_card_component forgot_password_form">
    <div class="heading text_align_center">
      <div class="header">Forgot Password</div>
      <div class="sub_header">Please enter your email address</div>
    </div>

    <form [formGroup]="forgotPasswordLoginForm" (ngSubmit)="submitEmail()">
      <label for="email" class="required_field_label">Email</label>
      <input
        id="email"
        name="email"
        formControlName="email"
        type="email"
        placeholder="Enter your email"
        required
        autocomplete="email"
        [ngClass]="{
          form_error:
            forgotPasswordLoginForm.get('email')?.invalid &&
            forgotPasswordLoginForm.get('email')?.touched &&
            forgotPasswordLoginForm.get('email')?.errors
        }"
      />
      <div
        class="validation_error"
        *ngIf="
          forgotPasswordLoginForm.get('email')?.invalid &&
          forgotPasswordLoginForm.get('email')?.touched
        "
      >
        <small
          *ngIf="forgotPasswordLoginForm.get('email')?.errors?.['required']"
          >Email is required</small
        >
        <small *ngIf="forgotPasswordLoginForm.get('email')?.errors?.['email']"
          >Invalid email format</small
        >
      </div>
      <button type="submit" class="primary_button">Proceed</button>
    </form>
  </div>
  }@else{
  <div class="login_card_component email_sent_form">
    <img
      class="success_email_banner"
      src="/assets/images/forgot_password_mail_sent.png"
      alt="Email Sent"
    />
    <div class="success_msg">
      An email has been sent to
      <span>{{ emailAddress || "your email" }}</span> with instructions for
      resetting the password. Please check your spam folder if you don't see an
      email in your inbox.
    </div>
    <div class="hint_msg">
      You can close this browser window now and continue the reset password
      process from mail.
    </div>
  </div>
  }
</div>
