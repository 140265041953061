import { Component, ViewChild } from '@angular/core';
import { TableBoxComponent } from '../../table-box/table-box.component';
import { ApiService } from '../../../services/api.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import {
  RoleStatsData,
  RoleStatsResponse,
} from '../../../models/role-stats-response.interface';
import { UserListRequest } from '../../../models/user-list-request.interface';
import { UserListDataResponse } from '../../../models/user-list-data-response';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { TableMenuComponent } from '../../table-menu/table-menu.component';
import { FormsModule } from '@angular/forms';
import { UserRolesPermissionsComponent } from '../../admin/user-roles-permissions/user-roles-permissions.component';
import { UserDialogComponent } from '../../admin/user-management/user-dialog/user-dialog.component';
import { UserManagementComponent } from '../../admin/user-management/user-management.component';
import {
  UserStatsData,
  UserStatsResponse,
} from '../../../models/user-stats-response.interface';
import { forkJoin } from 'rxjs';
import { LoadingService } from '../../../services/loading.service';
import { ToasterService } from '../../../services/toaster.service';
import { CustomerMasterComponent } from '../customer-master/customer-master.component';
import {
  CustomerStatsData,
  CustomerStatsResponse,
} from '../../../models/customer-stats-response.interface';

@Component({
  selector: 'app-master-management',
  standalone: true,
  imports: [
    CommonModule,
    TableBoxComponent,
    SvgIconComponent,
    DropdownComponent,
    TooltipDirective,
    TableMenuComponent,
    UserDialogComponent,
    FormsModule,
    UserRolesPermissionsComponent,
    UserManagementComponent,
    CustomerMasterComponent,
  ],
  templateUrl: './master-management.component.html',
  styleUrl: './master-management.component.scss',
})
export class MasterManagementComponent {
  @ViewChild(CustomerMasterComponent)
  customerMasterComponent!: CustomerMasterComponent;

  customerListData: UserListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  customerStats: CustomerStatsData = {
    total_customers: 0,
    active_customers: 0,
    inactive_customers: 0,
  };
  sortDirection: 'asc' | 'desc' = 'desc';
  sortColumn: string = 'modifiedAt';
  customerMasterListRequest: UserListRequest = {
    selectFields: [
      'id',
      'code',
      'name',
      'customerType',
      'createdBy.name as createdBy',
      'modifiedBy.name as modifiedBy',
      'createdAt',
      'modifiedAt',
      'active',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: 100,
    page: 0,
  };
  focusSearch: boolean = false;
  searchQuery!: string;

  constructor(
    private api: ApiService,
    private loaderService: LoadingService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.fetchAllData();
  }
  fetchAllData(): void {
    this.loaderService.show();
    const getCustomerStatsApiCall = this.api.getCustomerMasterStats();
    const getCustomerMasterList = this.api.getCustomerMasterList(
      this.customerMasterListRequest
    );

    forkJoin([getCustomerStatsApiCall, getCustomerMasterList]).subscribe({
      next: ([customerStats, customerMasterList]) => {
        console.log(customerStats);
        this.customerStats = customerStats.data;
        this.customerListData = customerMasterList.data;
        // console.log(customerMasterList);
        this.loaderService.hide();
      },
      error: (err) => {
        console.error(err);
        // this.toasterService.error(err.message);
        this.loaderService.hide();
      },
    });
  }

  getCustomerStats() {
    this.api.getCustomerMasterStats().subscribe({
      next: (res: CustomerStatsResponse) => {
        this.customerStats = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  getCustomersList() {
    this.api.getCustomerMasterList(this.customerMasterListRequest).subscribe({
      next: (res) => {
        this.customerListData = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  refreshUserList() {
    this.getCustomersList();
  }

  sortUsersList(orderBy: UserListRequest['orderBy']) {
    this.customerMasterListRequest.orderBy = orderBy;
    this.getCustomersList();
  }

  filterUsersList(where: UserListRequest['where']) {
    this.customerMasterComponent.currentTablePage = 1;
    this.customerMasterComponent.clearSearchQuery();
    this.customerMasterListRequest.where = where;
    this.getCustomersList();
  }

  searchUsersList(where: UserListRequest['where']) {
    this.customerMasterComponent.currentTablePage = 1;
    this.customerMasterListRequest.where = where;
    this.getCustomersList();
  }

  refreshUsersListWithStats() {
    this.getCustomersList();
    this.getCustomerStats();
  }

  handleUserTableSearch(ev: KeyboardEvent) {
    const key = ev?.key;
    if (this.searchQuery.length === 0) {
      delete this.customerMasterListRequest.where;
      this.refreshUserList();
    }
  }

  userTableSearch() {
    if (this.searchQuery) {
      this.customerMasterListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [this.searchQuery],
          },
        ],
        expression: 'A',
      };
    } else {
      delete this.customerMasterListRequest.where;
    }
    this.refreshUserList();
  }

  childTableSearch() {
    this.userTableSearch();
  }

  handleChildTableSearch(ev: KeyboardEvent) {
    this.handleUserTableSearch(ev);
  }

  resetSearch() {
    this.customerMasterComponent.clearSearchQuery();
  }
}
