<div class="container">
  <div class="header header_with_back_button">
    <app-back-button></app-back-button>
    <span>
        @if (readOnlyMode) { View } @else if(masterEditingEnabled) { Edit } @else
        {Create New} State
      </span>
  </div>

  <div class="container-content">
    <form [formGroup]="masterForm">
      <div class="section">
        <div class="head">Basic Information</div>
        <div class="fields">
          <div class="grid-container">
            <div class="form-group">
              <label for="name" class="required_field_label" #formField
              >State Name</label
              >
              <input
                id="name"
                name="name"
                formControlName="name"
                type="text"
                placeholder="Enter name"
                required
                autocomplete="name"
                [ngClass]="{
                    form_error:
                      masterForm.get('name')?.invalid &&
                      masterForm.get('name')?.touched &&
                      masterForm.get('name')?.errors
                  }"
              />
              <div
                class="form_validation_error"
                *ngIf="
                    masterForm.get('name')?.invalid &&
                    masterForm.get('name')?.touched
                  "
              >
                <small *ngIf="masterForm.get('name')?.errors?.['required']"
                >Name is required</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="code" class="required_field_label" #code
              >State Code</label
              >
              <input
                id="code"
                name="code"
                formControlName="code"
                type="text"
                placeholder="Enter Code"
                required
                autocomplete="code"
                [ngClass]="{
                    form_error:
                      masterForm.get('code')?.invalid &&
                      masterForm.get('code')?.touched &&
                      masterForm.get('code')?.errors
                  }"
              />
              <div
                class="form_validation_error"
                *ngIf="
                    masterForm.get('code')?.invalid &&
                    masterForm.get('code')?.touched
                  "
              >
                <small *ngIf="masterForm.get('code')?.errors?.['required']"
                >Code is required</small
                >
              </div>
            </div>
            <div class="form-group">
              <label for="countryId" class="required_field_label" #code
              >Country</label
              >
              <input
                id="countryId"
                name="countryId"
                formControlName="countryId"
                type="text"
                placeholder="Entry Country Name"
                required
                autocomplete="countryId"
                [ngClass]="{
                    form_error:
                      masterForm.get('countryId')?.invalid &&
                      masterForm.get('countryId')?.touched &&
                      masterForm.get('countryId')?.errors
                  }"
              />
              <div
                class="form_validation_error"
                *ngIf="
                    masterForm.get('countryId')?.invalid &&
                    masterForm.get('countryId')?.touched
                  "
              >
                <small *ngIf="masterForm.get('countryId')?.errors?.['required']"
                >Code is required</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>




    </form>
  </div>
  <div class="footer_actions">
    <ng-container *ngIf="readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Back
      </button>
    </ng-container>
    <ng-container *ngIf="!readOnlyMode">
      <button type="button" class="secondary_button" (click)="goBack()">
        Cancel
      </button>
      <button
        type="button"
        class="primary_button"
        (click)="submitMasterForm()"
      >
        @if(masterEditingEnabled){ Update }@else { Save }
      </button>
    </ng-container>
  </div>

</div>
