<div class="container">
  <div class="header">Admin</div>
  <div class="tab-area">
    <div class="tabs-container">
      <div class="tab-headers">
        <div
          [class.active]="selectedTab === 'users'"
          (click)="selectTab('users')"
          class="tab-header"
        >
          Users
        </div>
        <div
          [class.active]="selectedTab === 'roles'"
          (click)="selectTab('roles')"
          class="tab-header"
        >
          Roles
          <!-- Roles & Permissions -->
        </div>
      </div>
    </div>
    <div class="search-container" [class.search-input-focus]="focusSearch">
      <input
        type="text"
        name="search_users"
        id="search_users"
        [(ngModel)]="searchQuery"
        [placeholder]="
          selectedTab === 'users'
            ? 'Search by name / email'
            : 'Search by role name'
        "
        (focus)="focusSearch = true"
        (focusout)="focusSearch = false"
        (keyup.enter)="childTableSearch()"
        (keyup)="handleChildTableSearch($event)"
      />
      <button (click)="childTableSearch()">
        <app-svg-icon [path]="'assets/icons/search_icon.svg'"></app-svg-icon>
      </button>
    </div>
  </div>

  <div class="tab-content">
    <app-user-management
      [hidden]="selectedTab !== 'users'"
      [userStats]="userStats"
      [userListData]="userListData"
      (getUserStats)="getUserStats()"
      (getUsersList)="getUsersList()"
      (refreshUserList)="refreshUserList()"
      (refreshUsersListWithStats)="refreshUsersListWithStats()"
      (sortUsersList)="sortUsersList($event)"
      (filterUsersList)="filterUsersList($event)"
    ></app-user-management>
    <app-user-roles-permissions
      [hidden]="selectedTab !== 'roles'"
      [roleStats]="roleStats"
      [roleListData]="roleListData"
      (getRoleStats)="getRoleStats()"
      (getRolesList)="getRolesList()"
      (refreshRoleList)="refreshRoleList()"
      (refreshRolesListWithStats)="refreshRolesListWithStats()"
      (sortRolesList)="sortRolesList($event)"
      (filterRolesList)="filterRolesList($event)"
    ></app-user-roles-permissions>
  </div>
</div>
