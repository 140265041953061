import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '../../modules/shared/shared.module';
import { OtpInputComponent } from '../otp-input/otp-input.component';
import { ApiService } from '../../services/api.service';
import { LoginRequest } from '../../models/login-request.interface';
import { interval, Subscription } from 'rxjs';
import { ToasterService } from '../../services/toaster.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../services/loading.service';
import { AuthService } from '../../services/auth.service';
import { LocalStorageService } from '../../services/local-storage.service';
@Component({
  selector: 'app-login',
  standalone: true,
  imports: [SharedModule, OtpInputComponent],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  emailLoginForm: FormGroup;
  phoneLoginForm: FormGroup;
  loginMethod: 'email' | 'phone' = 'email';
  isOtpGenerated: boolean = false;
  otp: string = '';
  resendOtpBufferTime: number = 30; //in seconds
  countdown: number = 0;
  resendAllowed: boolean = false;
  countdownSubscription: Subscription = new Subscription();

  @ViewChild(OtpInputComponent) otpInputComponent!: OtpInputComponent;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private router: Router,
    private loadingService: LoadingService,
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {
    this.emailLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      // rememberMe: [''],
    });
    this.phoneLoginForm = this.fb.group({
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      otp: ['', [Validators.required, Validators.pattern('^[0-9]{6}$')]],
    });
    this.phoneLoginForm.get('mobile')?.valueChanges.subscribe(() => {
      this.isOtpGenerated = false;
    });
  }

  async ngOnInit() {
    const storedLoginMethod: any = await this.localStorageService.getItem(
      'loginMethod'
    );
    if (storedLoginMethod?.selected) {
      this.loginMethod = storedLoginMethod?.selected;
    } else {
      this.localStorageService.setItem('loginMethod', {
        selected: 'email',
      });
    }
    // const lastLoggedInUser: any = await this.localStorageService.getItem(
    //   'lastLoggedInUser'
    // );
    // if (lastLoggedInUser) {
    //   this.emailLoginForm
    //     .get('email')
    //     ?.setValue(lastLoggedInUser.creds.username);
    //   this.emailLoginForm
    //     .get('password')
    //     ?.setValue(lastLoggedInUser.creds.password);
    //   this.emailLoginForm.get('rememberMe')?.setValue(true);
    // }
  }

  // Switches the login method
  switchLoginMethod(method: 'email' | 'phone'): void {
    this.loginMethod = method;
    this.localStorageService.setItem('loginMethod', {
      selected: this.loginMethod,
    });
  }

  requestOtp() {
    if (this.phoneLoginForm.get('mobile')?.valid) {
      const mobileNumber = this.phoneLoginForm.get('mobile')?.value;
      this.loadingService.show();
      this.api.sendOtp(mobileNumber).subscribe({
        next: (res) => {
          this.toasterService.success(`${res.data}`);
          this.isOtpGenerated = true;
          this.startCountdown();
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
        error: (err) => {
          console.error(err);
          this.toasterService.error(err.error.errorDesc);
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
      });
    } else {
      this.phoneLoginForm.get('mobile')?.markAsTouched();
    }
  }

  resendOtp() {
    if (!this.resendAllowed) {
      return;
    } else {
      this.phoneLoginForm.get('otp')?.reset();
      this.otpInputComponent.otpForm.reset();
    }
    this.requestOtp();
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
  }

  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  startCountdown(): void {
    this.resendAllowed = false;
    this.countdown = this.resendOtpBufferTime;
    this.countdownSubscription = interval(1000).subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.resendAllowed = true;
        this.countdownSubscription.unsubscribe();
      }
    });
  }

  // Handle OTP change
  onOtpChange(newOtp: string): void {
    this.phoneLoginForm.get('otp')?.setValue(newOtp);
  }

  async submitEmailLogin() {
    this.emailLoginForm.markAllAsTouched();
    if (this.emailLoginForm.valid) {
      const loginRequest: LoginRequest = {
        email: this.emailLoginForm.get('email')?.value.toLowerCase(),
        password: this.emailLoginForm.get('password')?.value,
      };
      this.loadingService.show();
      this.authService.login(loginRequest).subscribe({
        next: () => {
          // redirection will be handled by redirectInterceptor
          this.toasterService.success('Login successful!');
          this.saveUserProfile();

          // if (this.emailLoginForm.get('rememberMe')?.value) {
          //   this.localStorageService.setItem('lastLoggedInUser', {
          //     creds: {
          //       username: this.emailLoginForm.get('email')?.value,
          //       password: this.emailLoginForm.get('password')?.value,
          //     },
          //   });
          // } else {
          //   this.localStorageService.removeItem('lastLoggedInUser');
          // }
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
        error: (err) => {
          console.error('Login failed', err);
          switch (err.status) {
            case 400:
              if (err.error.errorDesc.includes('Invalid email or mobile')) {
                this.toasterService.error('Incorrect email or password!');
              } else if (err.error.errorDesc.includes('Incorrect password')) {
                const remainingAttempts = parseInt(
                  err.error.data.remainingAttempts
                );
                if (remainingAttempts === 0) {
                  this.toasterService.error(
                    `Invalid login attempt. Your account has been locked as you have exceeded maximum login retries. Please contact the administrator for assistance.`
                  );
                } else {
                  this.toasterService.error(
                    `Invalid login attempt. You have ${remainingAttempts} ${
                      remainingAttempts === 1 ? 'attempt' : 'attempts'
                    } left !`
                  );
                }
              } else {
                this.toasterService.error(
                  err.error.errorDesc || 'Unknown Error !'
                );
              }
              break;
            default:
              this.toasterService.error(err.error.errorDesc);
              break;
          }
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
      });
    }
  }

  async submitPhoneLogin() {
    this.phoneLoginForm.markAllAsTouched();
    if (this.phoneLoginForm.valid) {
      const loginRequest: LoginRequest = {
        mobile: this.phoneLoginForm.get('mobile')?.value,
        otp: this.phoneLoginForm.get('otp')?.value,
      };
      this.loadingService.show();
      this.authService.login(loginRequest).subscribe({
        next: () => {
          // redirection will be handled by redirectInterceptor
          this.toasterService.success('Login successful!');
          this.saveUserProfile();
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
        error: (err) => {
          console.error('Login failed', err);
          switch (err.status) {
            case 400:
              if (err.error.errorDesc.includes('Incorrect OTP')) {
                const remainingAttempts = parseInt(
                  err.error.data.remainingAttempts
                );
                if (remainingAttempts === 0) {
                  this.toasterService.error(
                    `Invalid login attempt. Your account has been locked as you have exceeded maximum login retries. Please contact the administrator for assistance.`
                  );
                } else {
                  this.toasterService.error(
                    `Invalid login attempt. You have ${remainingAttempts} ${
                      remainingAttempts === 1 ? 'attempt' : 'attempts'
                    } left !`
                  );
                }
              } else {
                this.toasterService.error(
                  err.error.errorDesc || 'Unknown Error !'
                );
              }
              break;
            default:
              this.toasterService.error(err.error.errorDesc);
              break;
          }
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
      });
    }
  }

  navigate(route: string) {
    this.router.navigate([route]);
  }

  saveUserProfile() {
    this.api.getMyProfile().subscribe({
      next: (res) => {
        console.log(res);
        this.localStorageService.setItem(
          'userProfile',
          JSON.stringify(res.data)
        );
      },
    });
  }
}
