import {
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
})
export class ModalComponent {
  @Input() title: string = '';
  @Output() close = new EventEmitter<void>();
  @Output() refreshParent = new EventEmitter<void>();

  @ViewChild('modalContent', { read: ViewContainerRef, static: true })
  modalContent!: ViewContainerRef;

  onClose(params?: any) {
    this.close.emit(params);
  }

  loadComponent(component: Type<any>, data?: Partial<any>): ComponentRef<any> {
    this.modalContent.clear(); // Clear any previously loaded content
    const componentRef = this.modalContent.createComponent(component);

    // Pass the closeModal function to the dynamically loaded component
    if (componentRef.instance) {
      (componentRef.instance as any).closeModal = this.onClose.bind(this);

      if (data) {
        Object.assign(componentRef.instance, data); // Pass the data to the component
      }
    }

    return componentRef;
  }
}
