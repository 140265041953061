import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../../../services/api.service';
import { SelectComponent } from '../../../select/select.component';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { ToasterService } from '../../../../services/toaster.service';
import { LoadingService } from '../../../../services/loading.service';
import { User, UserRole } from '../../../../models/user.interface';
import { MaterialModule } from '../../../../modules/material/material.module';

@Component({
  selector: 'app-user-dialog',
  standalone: true,
  imports: [SharedModule, SelectComponent, SvgIconComponent, MaterialModule],
  templateUrl: './user-dialog.component.html',
  styleUrl: './user-dialog.component.scss',
})
export class UserDialogComponent {
  @Input() userEditingEnabled: boolean = false;
  @Input() userId!: string;
  @Input() userObj!: User;

  userForm: FormGroup;
  roles: UserRole[] = [];
  selectedRoles: UserRole[] = [];
  preSelectedOptions: UserRole[] = [];
  dropdownOpen = false;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService
  ) {
    this.userForm = this.fb.group(
      {
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
        phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
        loginMode: ['email', Validators.required],
        roles: [[]],
        isAdmin: [false],
      },
      { validators: this.passwordsMatchValidator } as AbstractControlOptions
    );
  }

  ngOnInit() {
    this.api.getRoles().subscribe({
      next: (res: any) => {
        this.roles = res.data.records;
      },
      error: (err) => {
        console.error(err);
        this.roles = [];
      },
    });

    if (this.userObj) {
      this.userForm.patchValue({
        name: this.userObj.name,
        email: this.userObj.email,
        newPassword: '',
        confirmPassword: '',
        phone: this.userObj.mobile,
        loginMode: 'email',
        roles: this.userObj.roles,
        isAdmin: this.userObj.isSuperAdmin,
      });
    }
  }

  compareRoles(role1: any, role2: any): boolean {
    return role1 && role2 ? role1.id === role2.id : role1 === role2;
  }

  removeChip(role: any) {
    const roleId = role.id;
    const roleIdx = this.userForm
      .get('roles')
      ?.value.findIndex((x: any) => x.id === roleId);
    if (roleIdx >= 0) {
      this.userForm.get('roles')?.value.splice(roleIdx, 1);
      this.userForm.get('roles')?.patchValue(this.userForm.get('roles')?.value);
    }
  }

  passwordsMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('newPassword')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  closeModal!: (params?: any) => void; // This will be provided by the ModalComponent

  close(params?: any) {
    if (this.closeModal) {
      this.closeModal(params);
    }
  }

  add() {
    console.log('add');
  }

  onSelectionChange(selectedValue: string | string[]) {
    console.log('Selected value(s):', selectedValue);
    this.userForm.controls['roles'].setValue(selectedValue);
    this.userForm.controls['roles'].updateValueAndValidity();
  }

  async submitUserForm() {
    this.userForm.markAllAsTouched();
    if (this.userForm.valid) {
      let selectedRoleIds: string[] = [];
      this.userForm.value.roles?.map((role: any) => {
        console.log(role);
        selectedRoleIds.push(role.id);
      });
      console.log('valid form');
      const userObj = {
        name: this.userForm.value.name,
        email: this.userForm.value.email,
        password: this.userForm.value.newPassword,
        confirmationPassword: this.userForm.value.confirmPassword,
        mobile: this.userForm.value.phone,
        active: true,
        admin: this.userForm.value.isAdmin,
        loginMode: this.userForm.value.loginMode,
        roles: selectedRoleIds,
      };
      console.log(userObj);
      if (this.userEditingEnabled) {
        this.updateUser(userObj, this.userId);
      } else {
        this.addUser(userObj);
      }
    } else {
      console.log('invalid form');
    }
    console.log(this.userForm.value);
    console.log('submitUserForm');
  }

  addUser(userObj: any) {
    this.loadingService.show();
    this.api.addUser(userObj).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(`User ${userObj.name} has been added !`);
        this.closeModal({
          refresh: true,
        });
        this.loadingService.hide();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }

  updateUser(userObj: any, userId: string) {
    this.loadingService.show();
    this.api.updateUser(userObj, userId).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(`User ${userObj.name} has been updated !`);
        this.closeModal({
          refresh: true,
        });
        this.loadingService.hide();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }
}
