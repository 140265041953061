<div
  class="container full_page_container login_system_bkg_cover centered_flex_container"
>
  <div class="login_card_component">
    <!-- <div class="logo">
      <img src="assets/images/logo.png" alt="SmartTOS | Globicon" />
    </div> -->

    <img src="assets/images/404.svg" alt="Not Found" />

    <div class="heading text_align_center">
      <div class="header">Oops! The Page is Out of This World</div>
      <div class="sub_header">
        Our servers are on a cosmic popcorn break, and the page you're looking
        for is lost in space. But don't worry, we'll beam you back to safety!
      </div>
    </div>
    <button class="primary_button" (click)="goToHome()">
      Go back to the homepage
    </button>
  </div>
</div>
