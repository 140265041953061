import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ForgotPasswordRequest } from '../models/forgot-password.interface';
import { ResetPasswordRequest } from '../models/reset-password.interface';
import { UserListRequest } from '../models/user-list-request.interface';
import { Customer } from '../models/customer-master.interface';
import { Role } from '../models/role.interface';

const sendOTPEndPoint = 'v1/verification/otp/send';
const forgotPasswordEndPoint = 'v1/reset-password';
const validateTokenForResetPasswordEndPoint =
  'v1/reset-password/validate-token';
const resetPasswordEndPoint = 'v1/reset-password';
const determinantsEndPoint = 'v1/determinants';
const usersEndPoint = 'v1/users';
const rolesEndPoint = 'v1/roles';
const customerMasterEndPoint = 'v1/customer-masters';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) {}

  sendOtp(mobileNumber: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${sendOTPEndPoint}`, {
      mobile: mobileNumber,
      // allowNotificationOnWhatsApp: true,
    });
  }

  forgotPassword(reqBody: ForgotPasswordRequest): Observable<any> {
    return this.http.post(`${this.baseUrl}/${forgotPasswordEndPoint}`, reqBody);
  }

  validateTokenForResetPassword(token: string): Observable<any> {
    return this.http.get(
      `${this.baseUrl}/${validateTokenForResetPasswordEndPoint}?reset_password_token=${token}`
    );
  }

  resetPassword(reqBody: ResetPasswordRequest, token: string): Observable<any> {
    return this.http.patch(
      `${this.baseUrl}/${resetPasswordEndPoint}?reset_password_token=${token}`,
      reqBody
    );
  }

  getDeterminants(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${determinantsEndPoint}`);
  }

  getUsers(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${usersEndPoint}/list`,
      userListRequest
    );
  }

  getUsersStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${usersEndPoint}/stats`);
  }

  getMyProfile(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${usersEndPoint}/me`);
  }

  addUser(userObject: any) {
    return this.http.post(`${this.baseUrl}/${usersEndPoint}`, userObject);
  }

  updateUser(userObject: any, userId: string) {
    return this.http.put(
      `${this.baseUrl}/${usersEndPoint}/${userId}`,
      userObject
    );
  }

  setUserActiveStatus(shouldSetActive: boolean, userIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${usersEndPoint}/status?status=${shouldSetActive}`,
      userIdList
    );
  }

  setUserLock(shouldBeLocked: boolean, userIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${usersEndPoint}/lock?lock=${shouldBeLocked}`,
      userIdList
    );
  }

  setAdminAccessStatus(shouldSetAdmin: boolean, userId: string) {
    return this.http.put(`${this.baseUrl}/${usersEndPoint}/${userId}`, {
      admin: shouldSetAdmin,
    });
  }

  getRoles(limit: number = 200) {
    return this.http.post(`${this.baseUrl}/${rolesEndPoint}/list`, {
      selectFields: ['id', 'name'],
      orderBy: {
        name: 'asc',
      },
      limit,
      page: 0,
    });
  }

  getRolesStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${rolesEndPoint}/stats`);
  }

  getRolesList(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${rolesEndPoint}/list`,
      userListRequest
    );
  }

  addRole(roleObject: Role) {
    return this.http.post(`${this.baseUrl}/${rolesEndPoint}`, roleObject);
  }

  updateRole(roleObject: Role, roleId: string) {
    return this.http.put(
      `${this.baseUrl}/${rolesEndPoint}/${roleId}`,
      roleObject
    );
  }

  setRoleActiveStatus(shouldSetActive: boolean, rolesIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${rolesEndPoint}/status?status=${shouldSetActive}`,
      rolesIdList
    );
  }

  getCustomerMasterStats(): Observable<any> {
    return this.http.get(`${this.baseUrl}/${customerMasterEndPoint}/stats`);
  }

  getCustomerMasterList(userListRequest: UserListRequest): Observable<any> {
    console.log(userListRequest);
    return this.http.post(
      `${this.baseUrl}/${customerMasterEndPoint}/list`,
      userListRequest
    );
  }

  addCustomer(customerObject: Customer) {
    return this.http.post(
      `${this.baseUrl}/${customerMasterEndPoint}`,
      customerObject
    );
  }

  getCustomer(customerId: string) {
    return this.http.get(
      `${this.baseUrl}/${customerMasterEndPoint}/${customerId}`
    );
  }

  updateCustomer(customerObject: Customer, customerId: string) {
    return this.http.put(
      `${this.baseUrl}/${customerMasterEndPoint}/${customerId}`,
      customerObject
    );
  }

  setCustomerActiveStatus(shouldSetActive: boolean, customerIdList: string[]) {
    return this.http.patch(
      `${this.baseUrl}/${customerMasterEndPoint}/status?status=${shouldSetActive}`,
      customerIdList
    );
  }

  deleteCustomer(customerId: string) {
    return this.http.delete(
      `${this.baseUrl}/${customerMasterEndPoint}/${customerId}`
    );
  }
}
