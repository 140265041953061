<div class="container">
  <div class="header">
    <div>Admin</div>

    <div class="tabs-container">
      <div class="tabs">
        <div
          class="tab"
          [class.active]="selectedTab === 'users'"
          (click)="selectTab('users')"
        >
          Users
        </div>
        <div
          class="tab"
          [class.active]="selectedTab === 'roles'"
          (click)="selectTab('roles')"
        >
          Roles
        </div>
      </div>
    </div>
  </div>

  <div class="tab-content">
    <app-user-management
      [hidden]="selectedTab !== 'users'"
    ></app-user-management>
    <app-user-roles-permissions
      [hidden]="selectedTab !== 'roles'"
    ></app-user-roles-permissions>
  </div>
</div>
