<div
  class="auto-complete-dropdown-container"
  [ngClass]="{ showing_suggestions: showSuggestions }"
  (clickOutside)="showSuggestions = false"
>
  <div class="search-bar" [ngClass]="{ search_focused: focusSearch }">
    <input
      autocomplete="off"
      type="text"
      name="search"
      id="search"
      [placeholder]="placeholder"
      [(ngModel)]="searchTerm"
      (ngModelChange)="updateSearchTerm()"
      (focus)="onInputFocus()"
      (focusout)="onInputFocusOut()"
      (keyup.enter)="handleEnter($event)"
      [disabled]="disable"
    />
    <button>
      <app-svg-icon [path]="'assets/icons/search_icon.svg'"></app-svg-icon>
    </button>
  </div>
  <div
    class="search-results"
    [ngClass]="{ search_focused: focusSearch }"
    *ngIf="showSuggestions"
    (scroll)="onScroll($event)"
  >
    <div
      class="result"
      *ngFor="let result of suggestions"
      (click)="select(result)"
    >
      <span *ngIf="result.name.trim() === ''"> N/A </span>
      <span
        *ngIf="result.name.trim() !== ''"
        [innerHTML]="result.name.trim() | highlight : searchTerm"
      ></span>

      <div class="hint">
        Code :
        <span [innerHTML]="result.code | highlight : searchTerm"></span>
      </div>
    </div>

    <div class="result loading_results" *ngIf="loading">Loading...</div>

    <div class="result no_results" *ngIf="suggestions.length === 0 && !loading">
      No results!
    </div>
  </div>

  <!-- <cdk-virtual-scroll-viewport
    [itemSize]="50"
    [style.height.px]="calculateViewportHeight(suggestions.length)"
    class="search-results"
    [ngClass]="{ search_focused: focusSearch }"
    *ngIf="showSuggestions || (focusSearch && suggestions.length > 0)"
    (scrolledIndexChange)="onScroll()"
  >
    <div
      class="result"
      *ngFor="let result of suggestions"
      (click)="select(result)"
    >
      <span *ngIf="result.name.trim() === ''"> N/A </span>
      <span
        *ngIf="result.name.trim() !== ''"
        [innerHTML]="result.name.trim() | highlight : searchTerm"
      ></span>

      <div class="hint">
        Code :
        <span [innerHTML]="result.code | highlight : searchTerm"></span>
      </div>
    </div>

    <div class="result loading_results" *ngIf="loading">Loading...</div>

    <div class="result no_results" *ngIf="suggestions.length === 0 && !loading">
      No results!
    </div>
  </cdk-virtual-scroll-viewport> -->
</div>
