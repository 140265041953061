import { Component, Input } from '@angular/core';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../services/api.service';
import { SelectComponent } from '../../../select/select.component';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { ToasterService } from '../../../../services/toaster.service';
import { LoadingService } from '../../../../services/loading.service';
import { MaterialModule } from '../../../../modules/material/material.module';
import { Role } from '../../../../models/role.interface';

@Component({
  selector: 'app-role-dialog',
  standalone: true,
  imports: [SharedModule, SelectComponent, SvgIconComponent, MaterialModule],
  templateUrl: './role-dialog.component.html',
  styleUrl: './role-dialog.component.scss',
})
export class RoleDialogComponent {
  @Input() isEditingEnabled: boolean = false;
  @Input() roleId!: string;
  @Input() roleObj!: Role;

  roleForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService
  ) {
    this.roleForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      active: [true],
      // cfsId: ['ICTPL, Nhava Sheva', Validators.required],
    });
  }

  ngOnInit() {
    console.log('ngOnInit: Role Dialog');
    if (this.roleObj) {
      console.log(this.isEditingEnabled, this.roleId);
      this.roleForm.patchValue({
        name: this.roleObj.name,
        description: this.roleObj.description,
      });
    }
  }

  closeModal!: (params?: any) => void; // This will be provided by the ModalComponent

  close(params?: any) {
    if (this.closeModal) {
      this.closeModal(params);
    }
  }

  async submitForm() {
    this.roleForm.markAllAsTouched();
    if (this.roleForm.valid) {
      console.log('valid form');
      const roleObj: Role = {
        name: this.roleForm.value.name,
        description: this.roleForm.value.description,
        // cfsId: this.roleForm.value.cfsId,
        active: this.roleForm.value.active,
        resourcePermissionList: [],
      };
      console.log(roleObj);
      if (this.isEditingEnabled) {
        this.updateRole(roleObj, this.roleId);
      } else {
        this.addRole(roleObj);
      }
    } else {
      console.log('invalid form');
    }
    console.log(this.roleForm.value);
    console.log('submitForm');
  }

  addRole(roleObj: Role) {
    this.loadingService.show();
    this.api.addRole(roleObj).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(`Role ${roleObj.name} has been added !`);
        this.closeModal({
          refresh: true,
        });
        this.loadingService.hide();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }

  updateRole(roleObj: Role, roleId: string) {
    this.loadingService.show();
    this.api.updateRole(roleObj, roleId).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(`Role ${roleObj.name} has been updated !`);
        this.closeModal({
          refresh: true,
        });
        this.loadingService.hide();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }
}
