<div
  class="container full_page_container login_system_bkg_cover centered_flex_container"
>
  <div class="login_card_component">
    <div class="logo">
      <img src="assets/images/logo.png" alt="SmartTOS | Globicon" />
    </div>

    <div class="heading text_align_center">
      <div class="header">Sign In</div>
      <div class="sub_header">Please fill your details</div>
    </div>

    <div class="tab_container">
      <div class="tabs">
        <div
          class="tab"
          [class.active]="loginMethod === 'email'"
          (click)="switchLoginMethod('email')"
        >
          Email
        </div>
        <div
          class="tab"
          [class.active]="loginMethod === 'phone'"
          (click)="switchLoginMethod('phone')"
        >
          Phone
        </div>
      </div>

      @if(loginMethod === 'email'){
      <form [formGroup]="emailLoginForm" (ngSubmit)="submitEmailLogin()">
        <label for="email" class="required_field_label">Email</label>
        <input
          id="email"
          name="email"
          formControlName="email"
          type="email"
          placeholder="Enter your email"
          required
          autocomplete="email"
          [ngClass]="{
            form_error:
              emailLoginForm.get('email')?.invalid &&
              emailLoginForm.get('email')?.touched &&
              emailLoginForm.get('email')?.errors
          }"
        />
        <div
          class="validation_error"
          *ngIf="
            emailLoginForm.get('email')?.invalid &&
            emailLoginForm.get('email')?.touched
          "
        >
          <small *ngIf="emailLoginForm.get('email')?.errors?.['required']"
            >Email is required</small
          >
          <small *ngIf="emailLoginForm.get('email')?.errors?.['email']"
            >Invalid email format</small
          >
        </div>
        <label for="password" class="required_field_label">Password</label>
        <input
          id="password"
          name="password"
          formControlName="password"
          type="password"
          placeholder="Enter your password"
          required
          [ngClass]="{
            form_error:
              emailLoginForm.get('password')?.invalid &&
              emailLoginForm.get('password')?.touched &&
              emailLoginForm.get('password')?.errors
          }"
        />
        <div
          class="validation_error"
          *ngIf="
            emailLoginForm.get('password')?.invalid &&
            emailLoginForm.get('password')?.touched
          "
        >
          <small *ngIf="emailLoginForm.get('password')?.errors?.['required']"
            >Password is required</small
          >
        </div>
        <div class="email_login_options spaced_flex_container flex_justify_end">
          <!-- <label class="label_with_checkbox">
            <input
              id="rememberMe"
              name="rememberMe"
              formControlName="rememberMe"
              type="checkbox"
            />
            Remember Me
          </label> -->
          <a (click)="navigate('/forgot-password')">Forgot Password?</a>
        </div>
        <button type="submit" class="primary_button">Sign in</button>
      </form>
      } @if(loginMethod === 'phone'){
      <form [formGroup]="phoneLoginForm" (ngSubmit)="submitPhoneLogin()">
        <label for="mobile" class="required_field_label">Phone Number</label>
        <input
          id="mobile"
          name="mobile"
          formControlName="mobile"
          type="tel"
          placeholder="Enter your phone number"
          required
          autocomplete="new-phone"
          maxLength="10"
          [ngClass]="{
            form_error:
              phoneLoginForm.get('mobile')?.invalid &&
              phoneLoginForm.get('mobile')?.touched &&
              phoneLoginForm.get('mobile')?.errors
          }"
        />
        <div
          class="validation_error"
          *ngIf="
            phoneLoginForm.get('mobile')?.invalid &&
            phoneLoginForm.get('mobile')?.touched
          "
        >
          <small *ngIf="phoneLoginForm.get('mobile')?.errors?.['required']"
            >Phone number is required</small
          >
          <small *ngIf="phoneLoginForm.get('mobile')?.errors?.['pattern']"
            >Enter 10 digits</small
          >
        </div>
        @if(isOtpGenerated){
        <!-- <div class="otp_sent_success">
          OTP sent successfully to your phone number
        </div> -->
        <label for="otp" class="required_field_label">OTP</label>
        <app-otp-input
          [ngClass]="{
            form_error:
              phoneLoginForm.get('otp')?.invalid &&
              phoneLoginForm.get('otp')?.touched &&
              phoneLoginForm.get('otp')?.errors
          }"
          id="otp"
          (otpChange)="onOtpChange($event)"
        ></app-otp-input>
        <div
          class="validation_error"
          *ngIf="
            phoneLoginForm.get('otp')?.invalid &&
            phoneLoginForm.get('otp')?.touched
          "
        >
          <small *ngIf="phoneLoginForm.get('otp')?.errors?.['required']"
            >OTP is required</small
          >
          <small *ngIf="phoneLoginForm.get('otp')?.errors?.['pattern']"
            >Enter 6 digits</small
          >
        </div>
        <div
          class="phone_login_options spaced_flex_container spaced_flex_container"
        >
          <div>
            <span class="countdown" *ngIf="!resendAllowed"
              >Resend OTP in {{ formatTime(countdown) }} seconds</span
            >
          </div>
          <div>
            <a [ngClass]="{ disabled: !resendAllowed }" (click)="resendOtp()"
              >Resend OTP</a
            >
          </div>
        </div>
        <button type="submit" class="primary_button">Sign in</button>
        }@else {
        <button type="button" class="primary_button" (click)="requestOtp()">
          Get OTP
        </button>
        }
      </form>
      }
    </div>
  </div>
</div>
