import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { TableBoxComponent } from '../../table-box/table-box.component';
import { ApiService } from '../../../services/api.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { RoleStatsData } from '../../../models/role-stats-response.interface';
import { UserListRequest } from '../../../models/user-list-request.interface';
import { UserListDataResponse } from '../../../models/user-list-data-response';
import { AdvancedFilterComponent } from '../../advanced-filter/advanced-filter.component';
import { ModalComponent } from '../../modal/modal.component';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { TableMenuComponent } from '../../table-menu/table-menu.component';
import { FormsModule } from '@angular/forms';
import { User } from '../../../models/user.interface';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ToasterService } from '../../../services/toaster.service';
import { RoleDialogComponent } from './role-dialog/role-dialog.component';
import { NoDataFoundComponent } from '../../no-data-found/no-data-found.component';
import { Role } from '../../../models/role.interface';

@Component({
  selector: 'app-user-roles-permissions',
  standalone: true,
  imports: [
    CommonModule,
    TableBoxComponent,
    SvgIconComponent,
    DropdownComponent,
    TooltipDirective,
    TableMenuComponent,
    FormsModule,
    UserRolesPermissionsComponent,
    NoDataFoundComponent,
  ],
  templateUrl: './user-roles-permissions.component.html',
  styleUrl: './user-roles-permissions.component.scss',
})
export class UserRolesPermissionsComponent {
  @Input() roleStats: RoleStatsData = {
    active_roles: 0,
    inactive_roles: 0,
    total_roles: 0,
  };
  @Input() roleListData: UserListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  @Output() getRolesList = new EventEmitter<void>();
  @Output() getUsersList = new EventEmitter<void>();
  @Output() refreshRoleList = new EventEmitter<void>();
  @Output() refreshRolesListWithStats = new EventEmitter<void>();
  @Output() sortRolesList = new EventEmitter<any>();
  @Output() filterRolesList = new EventEmitter<any>();

  // table pagination
  currentTablePage = 1;
  paginatedData: User[] = [];
  totalTablePages = 0;
  rowsPerPageOptions: number[] = [10, 20, 50, 100];
  rowsPerPage: number = 10;
  maxVisiblePages = 5;
  currentPage: number = 0;
  data: any[] = [];

  filterFields = [
    {
      displayName: 'Name',
      key: 'name',
    },
    {
      displayName: 'Description',
      key: 'email',
    },
    {
      displayName: 'Modified by',
      key: 'modifiedBy.name',
    },
  ];
  sortColumn: string = 'modifiedDate';
  sortDirection: 'asc' | 'desc' = 'desc';
  userListRequest: UserListRequest = {
    selectFields: [
      'name',
      'description',
      'isActive',
      'cfsId',
      'createdBy.name as createdBy',
      'createdDate',
      'modifiedBy.name as modifiedBy',
      'modifiedDate',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: 100,
    page: 0,
  };
  focusSearch: boolean = false;
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: 'Edit Role', value: 'edit' },
    // { label: 'Assign Users', value: 'assign' },
    // { label: 'View Asigned Users', value: 'viewAssignedUsers' },
    { label: 'Mark as inactive', value: 'inactive' },
    // { label: 'Clone Permissions', value: 'clone' },
    // { label: 'View Permissions', value: 'viewPermissions' },
    // { label: 'Edit', value: 'edit' },
    { label: 'Mark as active', value: 'active' },
    // { label: 'Mark as inactive', value: 'inactive' },
    // { label: 'Lock User', value: 'lock' },
    // { label: 'Unlock User', value: 'unlock' },
    // { label: 'Grant Admin Access', value: 'grantAdmin' },
    // { label: 'Revoke Admin Access', value: 'revokeAdmin' },
  ];
  selectedItems: any[] = []; // Selected items
  searchQuery!: string;
  tableLoaded: boolean = false;

  constructor(
    private api: ApiService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngOnChanges !');
    this.tableLoaded = false;
    // console.log(changes['roleListData']);
    if (changes['roleListData'] && !changes['roleListData'].firstChange) {
      console.log(this.roleListData);
      this.clearSelection();
      this.calculateTotalPages();
      this.updatePaginatedData();
      this.tableLoaded = true;
    }
  }

  calculateTotalPages() {
    console.log('calculateTotalPages has been called');
    console.log(this.roleListData);
    this.totalTablePages = Math.ceil(
      this.roleListData.records?.length / this.rowsPerPage
    );
    // console.log('this.totalTablePages : ' + this.totalTablePages);
  }

  updatePaginatedData() {
    const startIndex = (this.currentTablePage - 1) * this.rowsPerPage;
    const endIndex = startIndex + this.rowsPerPage;
    this.paginatedData = this.roleListData.records?.slice(startIndex, endIndex);
    console.log(this.paginatedData);
  }

  updateRowsPerPage(newRowsPerPage: number) {
    this.rowsPerPage = newRowsPerPage;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.updatePaginatedData();
  }

  goToPage(page: number) {
    this.currentTablePage = page;
    this.updatePaginatedData();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.updatePaginatedData();
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.updatePaginatedData();
    }
  }

  getSerialNumber(index: number): number {
    return (this.currentTablePage - 1) * this.rowsPerPage + index + 1;
  }

  isSelected(item: any): boolean {
    return this.selectedItems.includes(item);
  }

  isAllSelected(): boolean {
    return (
      this.roleListData.records?.length > 0 &&
      this.roleListData.records?.every((item) => this.isSelected(item))
    );
  }

  selectAll() {
    this.roleListData.records?.forEach((item) => {
      if (!this.isSelected(item)) {
        this.selectedItems.push(item);
      }
    });
  }

  clearSelection() {
    this.selectedItems = [];
  }

  toggleAllSelections(isChecked: boolean) {
    if (isChecked) {
      this.roleListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.roleListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i !== item);
      });
    }
  }

  toggleSelection(item: any) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i !== item);
    }
  }

  bulkActionConfirmation(actionName: string) {
    let actionTitle = 'Confirmation';
    let message = 'Are you sure ?';
    let successMsg = 'Done !';
    const selectedRoleCount = this.selectedItems.length;
    switch (actionName) {
      case 'setActive':
        actionTitle = `Activate ${
          this.selectedItems.length > 1 ? 'Roles' : 'Role'
        } Confirmation`;
        message = `Are you sure to activate ${
          this.selectedItems.length > 1
            ? ` the selected ${selectedRoleCount} roles?`
            : `${this.selectedItems[0].name}'s role?`
        }`;
        successMsg = `Successfully activated ${
          this.selectedItems.length > 1
            ? `${selectedRoleCount} roles`
            : `${this.selectedItems[0].name}'s role`
        }`;
        break;
      case 'setInActive':
        actionTitle = `Inactivate ${
          this.selectedItems.length > 1 ? 'Roles' : 'Role'
        } Confirmation`;
        message = `Are you sure to inactivate ${
          this.selectedItems.length > 1
            ? ` the selected ${selectedRoleCount} roles?`
            : `${this.selectedItems[0].name} role?`
        }`;
        successMsg = `Successfully inactivated ${
          this.selectedItems.length > 1
            ? `${selectedRoleCount} roles`
            : `${this.selectedItems[0].name} role`
        }`;
        break;
      default:
        console.error('unknown actionName');
        break;
    }
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = actionTitle;
    const confirmDialogRef = modalRef.instance.loadComponent(
      ConfirmDialogComponent
    );
    confirmDialogRef.instance.message = message;
    confirmDialogRef.instance.confirm.subscribe(() => {
      this.bulkAction(actionName, successMsg);
      modalRef.destroy();
    });
    confirmDialogRef.instance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.instance.close.subscribe(() => modalRef.destroy());
  }

  bulkAction(actionName: string, successMsg: string) {
    const selectedRoleIds = this.selectedItems.map((user: User) => {
      return user.id;
    });
    switch (actionName) {
      case 'setActive':
        this.api.setRoleActiveStatus(true, selectedRoleIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshRolesListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'setInActive':
        this.api.setRoleActiveStatus(false, selectedRoleIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshRolesListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.userListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection,
    };
    this.sortRolesList.emit(this.userListRequest.orderBy);
  }

  filter() {
    // console.log(this.userListRequest);
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Advanced Filter';
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );
    filterDialogRef.setInput('filterFields', this.filterFields);
    if (this.userListRequest.where) {
      filterDialogRef.setInput('appliedFilters', this.userListRequest.where);
    }
    modalRef.instance.close.subscribe((res: any) => {
      // console.log(res);
      if (res && res.resetAll) {
        delete this.userListRequest.where;
        this.filterRolesList.emit(this.userListRequest.where);
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.userListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression,
          };
        } else {
          console.error('Invalid filter !');
          delete this.userListRequest.where;
        }
        this.filterRolesList.emit(this.userListRequest.where);
      }
      modalRef.destroy();
    });
  }

  addNewRole() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Add Role';
    const userDialogRef = modalRef.instance.loadComponent(RoleDialogComponent);
    modalRef.instance.close.subscribe((res: any) => {
      if (res && res.refresh) {
        this.refreshRolesListWithStats.emit();
      }
      modalRef.destroy();
    });
  }

  onOptionSelected(option: string) {
    console.log('Selected option:', option);
  }

  handleMenuToggle(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? null : index;
  }

  handleOptionSelected(role: Role, actionName: string) {
    console.log(role);

    if (actionName === 'edit') {
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = 'Edit Role';
      const userDialogRef = modalRef.instance.loadComponent(
        RoleDialogComponent,
        role
      );
      userDialogRef.setInput('isEditingEnabled', true);
      userDialogRef.setInput('roleId', role.id);
      userDialogRef.setInput('roleObj', role);
      modalRef.instance.close.subscribe((res: any) => {
        if (res && res.refresh) {
          this.refreshRolesListWithStats.emit();
        }
        modalRef.destroy();
      });
    } else {
      // need to confirm before executing the action
      let actionTitle = 'Confirmation';
      let message = 'Are you sure ?';
      let successMsg = 'Done !';
      let flag = false;
      const roleId = role.id;

      switch (actionName) {
        case 'active':
        case 'inactive':
          if (actionName === 'active') {
            flag = true;
          }
          actionTitle = `${flag ? 'Activate' : 'Inactivate'} Role Confirmation`;
          message = `Are you sure to ${flag ? 'activate' : 'inactivate'} ${
            role.name
          } role ?`;
          successMsg = `Successfully ${flag ? 'activated' : 'inactived'} ${
            role.name
          } role`;
          break;
        default:
          console.error('unknown actionName');
          break;
      }
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = actionTitle;
      const confirmDialogRef = modalRef.instance.loadComponent(
        ConfirmDialogComponent
      );
      confirmDialogRef.instance.message = message;
      confirmDialogRef.instance.confirm.subscribe(() => {
        this.menuAction(actionName, successMsg, flag, roleId!);
        modalRef.destroy();
      });
      confirmDialogRef.instance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.instance.close.subscribe(() => modalRef.destroy());
    }
  }

  menuAction(
    actionName: string,
    successMsg: string,
    flag: boolean,
    userId: string
  ) {
    switch (actionName) {
      case 'active':
      case 'inactive':
        this.api.setRoleActiveStatus(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshRolesListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }
}
