import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '../../modules/shared/shared.module';

@Component({
  selector: 'app-otp-input',
  imports: [SharedModule, CommonModule],
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
  standalone: true,
})
export class OtpInputComponent {
  @Output() otpChange = new EventEmitter<string>();

  otpForm: FormGroup;

  @ViewChildren('otpInput') otpInputs!: QueryList<any>;

  constructor(private fb: FormBuilder) {
    this.otpForm = this.fb.group({
      digit1: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit2: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit3: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit4: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit5: ['', [Validators.required, Validators.pattern(/^\d$/)]],
      digit6: ['', [Validators.required, Validators.pattern(/^\d$/)]],
    });

    // Subscribe to changes in the form to emit the full OTP value
    this.otpForm.valueChanges.subscribe(() => {
      const otp = this.getOtpValue();
      this.otpChange.emit(otp);
    });
  }

  onKeyup(event: KeyboardEvent, index: number) {
    const key = event.key;
    if (key === 'Backspace' || key === 'Delete') {
      this.focusPrevInput(index);
    } else {
      this.focusNextInput(index);
    }
  }

  // Focus the next input field
  focusNextInput(index: number): void {
    const inputsArray = this.otpInputs.toArray();
    if (inputsArray[index] && index < 5) {
      inputsArray[index + 1].nativeElement.focus();
    }
  }

  // Focus the previous input field
  focusPrevInput(index: number): void {
    const inputsArray = this.otpInputs.toArray();
    if (inputsArray[index] && index > 0) {
      inputsArray[index - 1].nativeElement.focus();
    }
  }

  // Get the full OTP value
  private getOtpValue(): string {
    return Object.values(this.otpForm.value).join('');
  }
}
