import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { TableBoxComponent } from '../../table-box/table-box.component';
import { ApiService } from '../../../services/api.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { UserListRequest } from '../../../models/user-list-request.interface';
import { UserListDataResponse } from '../../../models/user-list-data-response';
import { AdvancedFilterComponent } from '../../advanced-filter/advanced-filter.component';
import { ModalComponent } from '../../modal/modal.component';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { TableMenuComponent } from '../../table-menu/table-menu.component';
import { FormsModule } from '@angular/forms';
import { User } from '../../../models/user.interface';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ToasterService } from '../../../services/toaster.service';
import { UserStatsData } from '../../../models/user-stats-response.interface';
import { NoDataFoundComponent } from '../../no-data-found/no-data-found.component';
import { UserDialogComponent } from '../../admin/user-management/user-dialog/user-dialog.component';
import { UserRolesPermissionsComponent } from '../../admin/user-roles-permissions/user-roles-permissions.component';
import { CustomerStatsData } from '../../../models/customer-stats-response.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { Customer } from '../../../models/customer-master.interface';

@Component({
  selector: 'app-customer-master',
  standalone: true,
  imports: [
    CommonModule,
    TableBoxComponent,
    SvgIconComponent,
    DropdownComponent,
    TooltipDirective,
    TableMenuComponent,
    UserDialogComponent,
    FormsModule,
    UserRolesPermissionsComponent,
    NoDataFoundComponent,
  ],
  templateUrl: './customer-master.component.html',
  styleUrl: './customer-master.component.scss',
})
export class CustomerMasterComponent {
  @Input() customerStats: CustomerStatsData = {
    total_customers: 0,
    active_customers: 0,
    inactive_customers: 0,
  };
  @Input() userListData: UserListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  @Output() getUserStats = new EventEmitter<void>();
  @Output() getUsersList = new EventEmitter<void>();
  @Output() refreshUserList = new EventEmitter<void>();
  @Output() refreshUsersListWithStats = new EventEmitter<void>();
  @Output() sortUsersList = new EventEmitter<any>();
  @Output() filterUsersList = new EventEmitter<any>();
  @Output() searchUsersList = new EventEmitter<any>();
  @Output() resetSearch = new EventEmitter<any>();

  // table pagination
  currentTablePage = 1;
  paginatedData: User[] = [];
  totalTablePages = 0;
  rowsPerPageOptions: number[] = [10, 20, 50, 100];
  rowsPerPage: number = 10;
  maxVisiblePages = 5;

  currentPage: number = 0;
  data: any[] = [];

  filterFields = [
    {
      displayName: 'Customer Name',
      key: 'name',
    },
    {
      displayName: 'Customer Code',
      key: 'code',
    },
    // {
    //   displayName: 'Customer Type',
    //   key: 'customerType',
    // },
  ];

  sortColumn: string = 'modifiedAt';
  sortDirection: 'asc' | 'desc' = 'desc';

  customerMasterListRequest: UserListRequest = {
    selectFields: [
      'id',
      'code',
      'name',
      'customerType',
      'createdBy.name as createdBy',
      'modifiedBy.name as modifiedBy',
      'createdAt',
      'modifiedAt',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: 100,
    page: 0,
  };

  focusSearch: boolean = false;
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Mark as active', value: 'active' },
    { label: 'Mark as inactive', value: 'inactive' },
    { label: 'View', value: 'view' },
    // { label: 'View Contact Details', value: 'viewContactDetails' },
    // { label: 'Customer Alias', value: 'customerAlias' },
  ];
  selectedItems: any[] = []; // Selected items
  searchQuery!: string;
  tableLoaded: boolean = false;

  constructor(
    private api: ApiService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngOnChanges !');
    this.tableLoaded = false;
    // console.log(changes['userListData']);
    if (changes['userListData'] && !changes['userListData'].firstChange) {
      console.log(this.userListData);
      this.clearSelection();
      this.calculateTotalPages();
      this.updatePaginatedData();
      this.tableLoaded = true;
    }
  }

  calculateTotalPages() {
    console.log('calculateTotalPages has been called');
    console.log(this.userListData);
    this.totalTablePages = Math.ceil(
      this.userListData.records?.length / this.rowsPerPage
    );
    console.log('this.totalTablePages : ' + this.totalTablePages);
  }

  updatePaginatedData() {
    const startIndex = (this.currentTablePage - 1) * this.rowsPerPage;
    const endIndex = startIndex + this.rowsPerPage;
    this.paginatedData = this.userListData.records?.slice(startIndex, endIndex);
    console.log(this.paginatedData);
  }

  updateRowsPerPage(newRowsPerPage: number) {
    this.rowsPerPage = newRowsPerPage;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.updatePaginatedData();
  }

  goToPage(page: number) {
    this.currentTablePage = page;
    this.updatePaginatedData();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.updatePaginatedData();
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.updatePaginatedData();
    }
  }

  getSerialNumber(index: number): number {
    return (this.currentTablePage - 1) * this.rowsPerPage + index + 1;
  }

  isSelected(item: any): boolean {
    return this.selectedItems.includes(item);
  }

  isAllSelected(): boolean {
    return (
      this.userListData.records?.length > 0 &&
      this.userListData.records?.every((item) => this.isSelected(item))
    );
  }

  selectAll() {
    this.userListData.records?.forEach((item) => {
      if (!this.isSelected(item)) {
        this.selectedItems.push(item);
      }
    });
  }

  clearSelection() {
    this.selectedItems = [];
  }

  toggleAllSelections(isChecked: boolean) {
    if (isChecked) {
      this.userListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.userListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i !== item);
      });
    }
  }

  toggleSelection(item: any) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i !== item);
    }
  }

  bulkActionConfirmation(actionName: string) {
    let actionTitle = 'Confirmation';
    let message = 'Are you sure ?';
    let successMsg = 'Done !';
    const selectedUserCount = this.selectedItems.length;
    switch (actionName) {
      case 'setActive':
        actionTitle = `Activate ${
          this.selectedItems.length > 1 ? 'Customers' : 'Customer'
        } Confirmation`;
        message = `Are you sure to activate ${
          this.selectedItems.length > 1
            ? ` the selected ${selectedUserCount} customer?`
            : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully activated ${
          this.selectedItems.length > 1
            ? `${selectedUserCount} customers`
            : `${this.selectedItems[0].name}`
        }`;
        break;
      case 'setInActive':
        actionTitle = `Inactivate ${
          this.selectedItems.length > 1 ? 'Customer' : 'Customers'
        } Confirmation`;
        message = `Are you sure to inactivate ${
          this.selectedItems.length > 1
            ? ` the selected ${selectedUserCount} customers?`
            : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully inactivated ${
          this.selectedItems.length > 1
            ? `${selectedUserCount} customers`
            : `${this.selectedItems[0].name}`
        }`;
        break;
      default:
        console.error('unknown actionName');
        break;
    }
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = actionTitle;
    const confirmDialogRef = modalRef.instance.loadComponent(
      ConfirmDialogComponent
    );
    confirmDialogRef.instance.message = message;
    confirmDialogRef.instance.confirm.subscribe(() => {
      this.bulkAction(actionName, successMsg);
      modalRef.destroy();
    });
    confirmDialogRef.instance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.instance.close.subscribe(() => modalRef.destroy());
  }

  bulkAction(actionName: string, successMsg: string) {
    const selectedUserIds = this.selectedItems.map((user: User) => {
      return user.id;
    });
    switch (actionName) {
      case 'setActive':
        this.api.setCustomerActiveStatus(true, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshUsersListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'setInActive':
        this.api.setCustomerActiveStatus(false, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshUsersListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.customerMasterListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection,
    };
    this.sortUsersList.emit(this.customerMasterListRequest.orderBy);
  }

  filter() {
    // console.log(this.customerMasterListRequest);
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Advanced Filter';
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );

    filterDialogRef.setInput('filterFields', this.filterFields);

    if (!this.searchQuery && this.customerMasterListRequest.where) {
      filterDialogRef.setInput(
        'appliedFilters',
        this.customerMasterListRequest.where
      );
    }

    modalRef.instance.close.subscribe((res: any) => {
      if (res && res.resetAll) {
        delete this.customerMasterListRequest.where;
        this.filterUsersList.emit(this.customerMasterListRequest.where);
        this.resetSearch.emit();
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.customerMasterListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression,
          };
        } else {
          console.error('Invalid filter !');
          delete this.customerMasterListRequest.where;
        }
        this.filterUsersList.emit(this.customerMasterListRequest.where);
      }
      modalRef.destroy();
    });
  }

  addNewUser() {
    this.router.navigate(['add-new'], { relativeTo: this.route });
  }

  onOptionSelected(option: string) {
    console.log('Selected option:', option);
  }

  handleMenuToggle(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? null : index;
  }

  handleOptionSelected(customer: Customer, actionName: string) {
    console.log(customer);
    const customerId = customer.id;
    if (actionName === 'edit') {
      this.router.navigate([`edit/${customerId}`], { relativeTo: this.route });
    } else if (actionName === 'view') {
      this.router.navigate([`view/${customerId}`], { relativeTo: this.route });
    } else {
      // need to confirm before executing the action
      let actionTitle = 'Confirmation';
      let message = 'Are you sure ?';
      let successMsg = 'Done !';
      let flag = false;
      switch (actionName) {
        case 'active':
        case 'inactive':
          if (actionName === 'active') {
            flag = true;
          }
          actionTitle = `${flag ? 'Activate' : 'Inactivate'} Customer`;
          message = `Are you sure to ${flag ? 'activate' : 'inactivate'} ${
            customer.name
          } ?`;
          successMsg = `Successfully ${flag ? 'activated' : 'inactived'} ${
            customer.name
          } !`;
          break;

        default:
          console.error('unknown actionName');
          break;
      }
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = actionTitle;
      const confirmDialogRef = modalRef.instance.loadComponent(
        ConfirmDialogComponent
      );
      confirmDialogRef.instance.message = message;
      confirmDialogRef.instance.confirm.subscribe(() => {
        this.menuAction(actionName, successMsg, flag, customerId!);
        modalRef.destroy();
      });
      confirmDialogRef.instance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.instance.close.subscribe(() => modalRef.destroy());
    }
  }

  menuAction(
    actionName: string,
    successMsg: string,
    flag: boolean,
    userId: string
  ) {
    switch (actionName) {
      case 'active':
      case 'inactive':
        this.api.setCustomerActiveStatus(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshUsersListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  handleCustomerTableSearch(ev: KeyboardEvent) {
    const key = ev?.key;
    if (this.searchQuery.length === 0) {
      delete this.customerMasterListRequest.where;
      this.searchUsersList.emit(this.customerMasterListRequest.where);
    }
  }

  customerTableSearch() {
    if (this.searchQuery) {
      this.customerMasterListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [this.searchQuery],
          },
        ],
        expression: 'A',
      };
    } else {
      delete this.customerMasterListRequest.where;
    }
    this.searchUsersList.emit(this.customerMasterListRequest.where);
  }

  clearSearchQuery() {
    this.searchQuery = '';
  }
}
