import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface LoaderConfig {
  isLoading: boolean;
  type: 'full' | 'small';
  dismissible?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loadingSubject: BehaviorSubject<LoaderConfig> =
    new BehaviorSubject<LoaderConfig>({
      isLoading: false,
      type: 'full',
      dismissible: false,
    });

  public loading$: Observable<LoaderConfig> =
    this.loadingSubject.asObservable();

  show(type: 'full' | 'small' = 'full', dismissible: boolean = false) {
    this.loadingSubject.next({ isLoading: true, type, dismissible });
  }

  hide() {
    this.loadingSubject.next({
      isLoading: false,
      type: 'full',
      dismissible: false,
    });
  }
}
