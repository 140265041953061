import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { AdvancedFilter } from '../../models/advanced-filter.interface';

@Component({
  selector: 'app-advanced-filter',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SvgIconComponent],
  templateUrl: './advanced-filter.component.html',
  styleUrl: './advanced-filter.component.scss',
})
export class AdvancedFilterComponent {
  @Input() appliedFilters!: AdvancedFilter;
  @Input() filterFields!: string[];

  filterForm: FormGroup;
  aliasCounter = 0;
  columns = [
    'name',
    'email',
    'mobile',
    'isActive',
    'isLocked',
    'isSuperAdmin',
    'roles',
  ];

  operators = [
    {
      displayName: 'Contains',
      operatorKey: 'CONTAINS',
    },
    {
      displayName: 'Do not contains',
      operatorKey: 'DOES_NOT_CONTAINS',
    },
    {
      displayName: 'Equals',
      operatorKey: 'EQ_CIS',
    },
    {
      displayName: 'Not equals',
      operatorKey: 'NE_CIS',
    },
    {
      displayName: 'Starts with',
      operatorKey: 'STARTS_WITH',
    },
    {
      displayName: 'Ends with',
      operatorKey: 'ENDS_WITH',
    },
  ];

  logicalOperators = ['AND', 'OR'];

  constructor(private fb: FormBuilder) {
    this.filterForm = this.fb.group({
      filterConditions: this.fb.array([]),
      finalExpression: this.fb.array([]),
      expression: [''],
    });
  }

  closeModal!: (params?: any) => void; // This will be provided by the ModalComponent

  close(params?: any) {
    if (this.closeModal) {
      this.closeModal(params);
    }
  }

  ngOnInit() {
    if (this.appliedFilters) {
      this.setFilters(this.appliedFilters);
    } else {
      this.addCondition();
    }

    this.filterForm.get('expression')?.valueChanges.subscribe((value) => {
      console.log('Textarea updated:', value);
    });
  }

  get filterConditions(): FormArray {
    return this.filterForm.get('filterConditions') as FormArray;
  }

  get finalExpression(): FormArray {
    return this.filterForm.get('finalExpression') as FormArray;
  }

  generateAlias(): string {
    const baseChar = 'A'.charCodeAt(0);
    let alias = '';
    let counter = this.aliasCounter;

    while (counter >= 0) {
      alias = String.fromCharCode(baseChar + (counter % 26)) + alias;
      counter = Math.floor(counter / 26) - 1;
    }

    this.aliasCounter++;
    return alias;
  }

  addCondition() {
    const alias = this.generateAlias();
    this.filterConditions.push(
      this.fb.group({
        name: ['', Validators.required],
        alias: [{ value: alias, disabled: true }, Validators.required],
        operator: ['', Validators.required],
        value: this.fb.array([''], Validators.required), // Value as an array of strings
      })
    );

    if (this.finalExpression.length > 0) {
      this.finalExpression.push(
        this.fb.group({
          type: 'operator',
          value: 'AND', // Default operator
        })
      );
    }

    this.finalExpression.push(
      this.fb.group({
        type: 'alias',
        value: alias,
      })
    );

    this.updateExpressionString();
  }

  removeCondition(index: number) {
    const alias = this.filterConditions.at(index).get('alias')?.value;
    this.filterConditions.removeAt(index);

    // Find the alias in finalExpression
    const aliasIndex = this.finalExpression.controls.findIndex(
      (group) =>
        group.get('value')?.value === alias &&
        group.get('type')?.value === 'alias'
    );

    if (aliasIndex !== -1) {
      this.finalExpression.removeAt(aliasIndex); // Remove alias

      // Remove preceding logical operator if exists (and not the first element)
      if (
        aliasIndex > 0 &&
        this.finalExpression.at(aliasIndex - 1)?.get('type')?.value ===
          'operator'
      ) {
        this.finalExpression.removeAt(aliasIndex - 1);
      }
    }

    this.updateExpressionString();
  }

  updateExpressionString() {
    console.log('updateExpressionString');
    const expressionString = this.finalExpression.controls
      .map((group) => group.get('value')?.value)
      .join(' ');
    this.filterForm.get('expression')?.setValue(expressionString);

    //   let processedExpressionString;
    //   if (expressionString.length) {
    //     processedExpressionString = '( ' + expressionString + ' )';
    //   }
    //   this.filterForm.get('expression')?.setValue(processedExpressionString);
  }

  updateAliases() {
    this.aliasCounter = 0;
    this.filterConditions.controls.forEach((group) => {
      group.get('alias')?.setValue(this.generateAlias());
    });
  }

  applyFilter() {
    this.cleanUnfilledConditions(); // Clean unfilled conditions before applying filter
    const filterData = this.filterForm.getRawValue();
    console.log(filterData);
    this.closeModal({
      refresh: true,
      filterData,
    });
  }

  cleanUnfilledConditions() {
    const filledConditions = this.filterConditions.controls.filter(
      (control, index) => {
        const name = control.get('name')?.value;
        const operator = control.get('operator')?.value;
        const valuesArray = control.get('value') as FormArray;
        const values = valuesArray.value;

        if (name && operator && values.every((value: string) => !!value)) {
          return true;
        } else {
          // Remove corresponding alias and operator from the finalExpression
          const alias = control.get('alias')?.value;
          let aliasIndex = this.finalExpression.controls.findIndex(
            (group) =>
              group.get('value')?.value === alias &&
              group.get('type')?.value === 'alias'
          );

          if (aliasIndex !== -1) {
            this.finalExpression.removeAt(aliasIndex);
            if (
              aliasIndex > 0 &&
              this.finalExpression.at(aliasIndex - 1)?.get('type')?.value ===
                'operator'
            ) {
              this.finalExpression.removeAt(aliasIndex - 1);
            }
          }

          return false;
        }
      }
    );

    this.filterForm.setControl(
      'filterConditions',
      this.fb.array(filledConditions)
    );
    this.updateExpressionString(); // Update the expression string after cleaning
  }

  setFilters(filterData: any) {
    console.log('setting filters now');
    const filterConditions = filterData.filterConditions || [];
    const expressionArray = filterData.expression
      ? filterData.expression.split(' ')
      : [];

    // Clear existing filter conditions and final expression
    this.filterConditions.clear();
    this.finalExpression.clear();
    this.aliasCounter = 0;

    // Track the maximum alias index found in filter data
    let maxAliasIndex = -1;

    // Loop through each filter condition from the provided filterData
    filterConditions.forEach((condition: any) => {
      this.filterConditions.push(
        this.fb.group({
          name: [condition.name, Validators.required],
          alias: [
            { value: condition.alias, disabled: true },
            Validators.required,
          ],
          operator: [condition.operator, Validators.required],
          value: this.fb.array(condition.value || [], Validators.required),
        })
      );

      // Push the alias to the finalExpression
      this.finalExpression.push(
        this.fb.group({
          type: 'alias',
          value: condition.alias,
        })
      );

      // Determine the alias index from its last character if it's numeric
      const aliasLastChar = condition.alias.slice(-1);
      const aliasIndex = isNaN(Number(aliasLastChar))
        ? condition.alias.charCodeAt(0) - 'A'.charCodeAt(0)
        : Number(aliasLastChar) +
          26 * (condition.alias.charCodeAt(0) - 'A'.charCodeAt(0));

      if (aliasIndex > maxAliasIndex) {
        maxAliasIndex = aliasIndex;
      }
    });

    // Loop through the expressionArray and fill finalExpression with operators
    for (let i = 0; i < expressionArray.length; i++) {
      if (this.logicalOperators.includes(expressionArray[i])) {
        this.finalExpression.insert(
          i,
          this.fb.group({
            type: 'operator',
            value: expressionArray[i],
          })
        );
      }
    }

    // Set aliasCounter to the next available alias index after maxAliasIndex
    this.aliasCounter = maxAliasIndex + 1;

    // Update the final expression string
    this.updateExpressionString();
  }

  // Helper function to set aliasCounter based on the last alias used
  incrementAliasCounter(alias: string) {
    const alphabet = alias.replace(/[0-9]/g, '');
    const number = parseInt(alias.replace(alphabet, ''), 10) || 0;
    this.aliasCounter =
      alphabet.charCodeAt(0) - 'a'.charCodeAt(0) + number * 26 + 1;
  }

  onOperatorChange(index: number, operator: string) {
    this.finalExpression.at(index).get('value')?.setValue(operator);
    this.updateExpressionString();
  }

  resetFilter() {
    console.log('clearFilters');
    this.closeModal({
      refresh: true,
      resetAll: true,
    });
  }
}
