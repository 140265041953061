import { Routes } from '@angular/router';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './layouts/private-layout/private-layout.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { ManageUsersComponent } from './components/admin/manage-users/manage-users.component';
import { SupportComponent } from './components/support/support.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { CustomerMasterComponent } from './components/masters/customer-master/customer-master.component';
import { ManageCustomerComponent } from './components/masters/customer-master/manage-customer/manage-customer.component';
import { LocationMasterComponent } from './components/masters/location-master/location-master.component';
import { ManageLocationComponent } from './components/masters/location-master/manage-location/manage-location.component';
import { CommodityCategoryMasterComponent } from './components/masters/commodity-category-master/commodity-category-master.component';
import { ManageCommodityCategoryComponent } from './components/masters/commodity-category-master/manage-commodity-category/manage-commodity-category.component';
import { CountyMasterComponent } from './components/masters/county-master/county-master.component';
import { ManageCountryMasterComponent } from './components/masters/county-master/manage-country-master/manage-country-master.component';
import { BankMasterComponent } from './components/masters/bank-master/bank-master.component';
import { ManageBankMasterComponent } from './components/masters/bank-master/manage-bank-master/manage-bank-master.component';
import { PackageMasterComponent } from './components/masters/package-master/package-master.component';
import { ManagePackageComponent } from './components/masters/package-master/manage-package/manage-package.component';
import { TrailerMasterComponent } from "./components/masters/trailer-master/trailer-master.component";
import {
  ManageTrailerMasterComponent
} from "./components/masters/trailer-master/manage-trailer-master/manage-trailer-master.component";
import { StateMasterComponent } from "./components/masters/state-master/state-master.component";
import {
  ManageStateMasterComponent
} from "./components/masters/state-master/manage-state-master/manage-state-master.component";
import { CityMasterComponent } from "./components/masters/city-master/city-master.component";
import { ManageCityComponent } from "./components/masters/city-master/manage-city/manage-city.component";
import { CommodityMasterComponent } from "./components/masters/commodity-master/commodity-master.component";
import {
  ManageCommodityComponent
} from "./components/masters/commodity-master/manage-commodity/manage-commodity.component";
import {
  VesselPortTrainDetailsMasterComponent
} from "./components/masters/vessel-port-train-details-master/vessel-port-train-details-master.component";
import {
  ManageVesselPortTrainDetailsComponent
} from "./components/masters/vessel-port-train-details-master/manage-vessel-port-train-details/manage-vessel-port-train-details.component";

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: PublicLayoutComponent,
    children: [{ path: '', component: LoginComponent }],
    canActivate: [LoginGuard],
  },
  {
    path: 'forgot-password',
    component: PublicLayoutComponent,
    children: [{ path: '', component: ForgotPasswordComponent }],
    canActivate: [LoginGuard],
  },
  {
    path: 'reset-password/:token',
    component: PublicLayoutComponent,
    children: [{ path: '', component: ResetPasswordComponent }],
    canActivate: [LoginGuard],
  },
  {
    path: 'dashboard',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: DashboardComponent }],
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/manage-users',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: ManageUsersComponent }],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/customers',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: CustomerMasterComponent },
      { path: 'add-new', component: ManageCustomerComponent },
      { path: 'edit/:customerId', component: ManageCustomerComponent },
      { path: 'view/:customerId', component: ManageCustomerComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/locations',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: LocationMasterComponent },
      { path: 'add-new', component: ManageLocationComponent },
      { path: 'edit/:locationId', component: ManageLocationComponent },
      { path: 'view/:locationId', component: ManageLocationComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/cities',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: CityMasterComponent },
      { path: 'add-new', component: ManageCityComponent },
      { path: 'edit/:cityId', component: ManageCityComponent },
      { path: 'view/:cityId', component: ManageCityComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/commodities',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: CommodityMasterComponent },
      { path: 'add-new', component: ManageCommodityComponent },
      {
        path: 'edit/:commodityId',
        component: ManageCommodityComponent,
      },
      {
        path: 'view/:commodityId',
        component: ManageCommodityComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/commodity-categories',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: CommodityCategoryMasterComponent },
      { path: 'add-new', component: ManageCommodityCategoryComponent },
      {
        path: 'edit/:commodityCategoryId',
        component: ManageCommodityCategoryComponent,
      },
      {
        path: 'view/:commodityCategoryId',
        component: ManageCommodityCategoryComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/countries',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: CountyMasterComponent },
      { path: 'add-new', component: ManageCountryMasterComponent },
      { path: 'edit/:countryId', component: ManageCountryMasterComponent },
      { path: 'view/:countryId', component: ManageCountryMasterComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/states',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: StateMasterComponent },
      { path: 'add-new', component: ManageStateMasterComponent },
      { path: 'edit/:stateId', component: ManageStateMasterComponent },
      { path: 'view/:stateId', component: ManageStateMasterComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/banks',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: BankMasterComponent },
      { path: 'add-new', component: ManageBankMasterComponent },
      { path: 'edit/:bankId', component: ManageBankMasterComponent },
      { path: 'view/:bankId', component: ManageBankMasterComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/packages',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: PackageMasterComponent },
      { path: 'add-new', component: ManagePackageComponent },
      { path: 'edit/:packageId', component: ManagePackageComponent },
      { path: 'view/:packageId', component: ManagePackageComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/trailers',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: TrailerMasterComponent },
      { path: 'add-new', component: ManageTrailerMasterComponent },
      { path: 'edit/:trailerId', component: ManageTrailerMasterComponent },
      { path: 'view/:trailerId', component: ManageTrailerMasterComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-masters/vessel-port-train-details',
    component: PrivateLayoutComponent,
    children: [
      { path: '', component: VesselPortTrainDetailsMasterComponent },
      { path: 'add-new', component: ManageVesselPortTrainDetailsComponent },
      { path: 'edit/:vesselPortTrainDetailId', component: ManageVesselPortTrainDetailsComponent },
      { path: 'view/:vesselPortTrainDetailId', component: ManageVesselPortTrainDetailsComponent },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'support',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: SupportComponent }],
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: SettingsComponent }],
    canActivate: [AuthGuard],
  },
  {
    path: 'user-profile',
    component: PrivateLayoutComponent,
    children: [{ path: '', component: UserProfileComponent }],
    canActivate: [AuthGuard],
  },

  // Wildcard route for a 404 page
  { path: '**', component: PageNotFoundComponent },
];
