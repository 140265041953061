import { Component, ViewChild } from '@angular/core';
import { TableBoxComponent } from '../../table-box/table-box.component';
import { ApiService } from '../../../services/api.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import {
  RoleStatsData,
  RoleStatsResponse,
} from '../../../models/role-stats-response.interface';
import { UserListRequest } from '../../../models/user-list-request.interface';
import { UserListDataResponse } from '../../../models/user-list-data-response';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { TableMenuComponent } from '../../table-menu/table-menu.component';
import { FormsModule } from '@angular/forms';
import { UserRolesPermissionsComponent } from '../user-roles-permissions/user-roles-permissions.component';
import { UserDialogComponent } from '../user-management/user-dialog/user-dialog.component';
import { UserManagementComponent } from '../user-management/user-management.component';
import {
  UserStatsData,
  UserStatsResponse,
} from '../../../models/user-stats-response.interface';
import { forkJoin } from 'rxjs';
import { LoadingService } from '../../../services/loading.service';
import { ToasterService } from '../../../services/toaster.service';

@Component({
  selector: 'app-manage-users',
  standalone: true,
  imports: [
    CommonModule,
    TableBoxComponent,
    SvgIconComponent,
    DropdownComponent,
    TooltipDirective,
    TableMenuComponent,
    UserDialogComponent,
    FormsModule,
    UserRolesPermissionsComponent,
    UserManagementComponent,
  ],
  templateUrl: './manage-users.component.html',
  styleUrl: './manage-users.component.scss',
})
export class ManageUsersComponent {
  userListData: UserListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  userStats: UserStatsData = {
    total_users: 0,
    active_users: 0,
    inactive_users: 0,
  };
  roleStats: RoleStatsData = {
    active_roles: 0,
    inactive_roles: 0,
    total_roles: 0,
  };
  roleListData: UserListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  selectedTab: string = 'users'; // 'users' // roles;
  sortDirection: 'asc' | 'desc' = 'desc';
  sortColumn: string = 'createdDate';
  userListRequest: UserListRequest = {
    selectFields: [
      'id',
      'name',
      'email',
      'mobile',
      'isActive',
      'isLocked',
      'isSuperAdmin',
      'roles',
      'createdDate',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: 100,
    page: 0,
  };
  roleListRequest: UserListRequest = {
    selectFields: [
      'id',
      'name',
      'description',
      'isActive',
      'cfsId',
      'createdBy.name as createdBy',
      'createdDate',
      'modifiedBy.name as modifiedBy',
      'modifiedDate',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: 100,
    page: 0,
  };
  focusSearch: boolean = false;
  searchQuery!: string;

  @ViewChild(UserManagementComponent)
  userManagementComponent!: UserManagementComponent;
  @ViewChild(UserRolesPermissionsComponent)
  userRolesPermissionsComponent!: UserRolesPermissionsComponent;

  constructor(
    private api: ApiService,
    private loaderService: LoadingService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.fetchAllData();
  }

  fetchAllData(): void {
    this.loaderService.show();
    const getUserStatsApiCall = this.api.getUsersStats();
    const getUsersListApiCall = this.api.getUsers(this.userListRequest);
    const getRoleStats = this.api.getRolesStats();
    const getRolesList = this.api.getRolesList(this.roleListRequest);

    forkJoin([
      getUserStatsApiCall,
      getUsersListApiCall,
      getRoleStats,
      getRolesList,
    ]).subscribe({
      next: ([userStats, usersList, roleStats, rolesList]) => {
        console.log(userStats);
        this.userStats = userStats.data;
        this.userListData = usersList.data;
        this.roleStats = roleStats.data;
        this.roleListData = rolesList.data;
        this.loaderService.hide();
      },
      error: (err) => {
        console.error(err);
        // this.toasterService.error(err.message);
        this.loaderService.hide();
      },
    });
  }

  getRoleStats() {
    this.api.getRolesStats().subscribe({
      next: (res: RoleStatsResponse) => {
        this.roleStats = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  getRolesList() {
    this.api.getRolesList(this.roleListRequest).subscribe({
      next: (res) => {
        this.roleListData = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }

  getUserStats() {
    this.api.getUsersStats().subscribe({
      next: (res: UserStatsResponse) => {
        this.userStats = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  getUsersList() {
    this.api.getUsers(this.userListRequest).subscribe({
      next: (res) => {
        this.userListData = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  refreshUserList() {
    this.userManagementComponent.currentTablePage = 1;
    this.getUsersList();
  }

  sortUsersList(orderBy: UserListRequest['orderBy']) {
    this.userListRequest.orderBy = orderBy;
    this.getUsersList();
  }

  filterUsersList(where: UserListRequest['where']) {
    this.searchQuery = '';
    this.userManagementComponent.currentTablePage = 1;
    this.userListRequest.where = where;
    this.getUsersList();
  }

  refreshUsersListWithStats() {
    this.getUsersList();
    this.getUserStats();
  }

  refreshRoleList() {
    this.userRolesPermissionsComponent.currentTablePage = 1;
    this.getRolesList();
  }

  sortRolesList(orderBy: UserListRequest['orderBy']) {
    this.roleListRequest.orderBy = orderBy;
    this.getRolesList();
  }

  filterRolesList(where: UserListRequest['where']) {
    this.searchQuery = '';
    this.userRolesPermissionsComponent.currentTablePage = 1;
    this.roleListRequest.where = where;
    this.getRolesList();
  }

  refreshRolesListWithStats() {
    this.getRolesList();
    this.getRoleStats();
  }

  handleUserTableSearch(ev: KeyboardEvent) {
    const key = ev?.key;
    if (this.searchQuery.length === 0) {
      delete this.userListRequest.where;
      this.refreshUserList();
    }
  }

  userTableSearch() {
    if (this.searchQuery) {
      this.userListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [this.searchQuery],
          },
          {
            name: 'email',
            alias: 'B',
            operator: 'CONTAINS',
            value: [this.searchQuery],
          },
        ],
        expression: 'A OR B',
      };
    } else {
      delete this.userListRequest.where;
    }
    this.refreshUserList();
  }

  handleRoleTableSearch(ev: KeyboardEvent) {
    const key = ev?.key;
    if (this.searchQuery.length === 0) {
      delete this.roleListRequest.where;
      this.refreshRoleList();
    }
  }

  roleTableSearch() {
    if (this.searchQuery) {
      this.roleListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [this.searchQuery],
          },
        ],
        expression: 'A',
      };
    } else {
      delete this.roleListRequest.where;
    }
    this.refreshRoleList();
  }

  childTableSearch() {
    this.userTableSearch();
    this.roleTableSearch();
    // if (this.selectedTab === 'users') {
    //   this.userTableSearch();
    // } else if (this.selectedTab === 'roles') {
    //   this.roleTableSearch();
    // } else {
    //   console.error('Unknown tab name');
    // }
  }

  handleChildTableSearch(ev: KeyboardEvent) {
    this.handleUserTableSearch(ev);
    this.handleRoleTableSearch(ev);
    // if (this.selectedTab === 'users') {
    //   this.handleUserTableSearch(ev);
    // } else if (this.selectedTab === 'roles') {
    //   this.handleRoleTableSearch(ev);
    // } else {
    //   console.error('Unknown tab name');
    // }
  }
}
