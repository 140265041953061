<div class="form-group" [ngClass]="{ 'has-error': showErrors() }">
  <label *ngIf="label">{{ label }}</label>
  <input
    [type]="type"
    [id]="id"
    [name]="name"
    [placeholder]="placeholder"
    [formControl]="control"
    [ngClass]="{ 'is-invalid': showErrors() }"
    (blur)="onBlur()"
    class="form-control"
  />
  <div *ngIf="showErrors()" class="error-message">
    <div *ngFor="let error of errorMessages">
      {{ error }}
    </div>
  </div>
</div>
