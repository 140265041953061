import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { MaterialModule } from '../../modules/material/material.module';

@Component({
  selector: 'app-table-menu',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, MaterialModule],
  templateUrl: './table-menu.component.html',
  styleUrl: './table-menu.component.scss',
})
export class TableMenuComponent {
  @Input() selectedRowIndex: number = 0;
  @Input() options: { label: string; value: any }[] = [];
  @Input() isOpen: boolean = false;
  @Input() tableRowData: any;
  @Output() optionSelected = new EventEmitter<any>();
  @Output() toggle = new EventEmitter<void>();

  @ViewChild('menu', { static: false }) menuElement!: ElementRef;

  isAbove: boolean = false;

  ngOnInit() {
    // console.log(this.tableRowData);
    if (this.tableRowData.isActive || this.tableRowData.active) {
      this.options = this.options.filter((i) => i.value !== 'active');
    } else {
      this.options = this.options.filter((i) => i.value !== 'inactive');
    }
    if (this.tableRowData.isLocked) {
      this.options = this.options.filter((i) => i.value !== 'lock');
    } else {
      this.options = this.options.filter((i) => i.value !== 'unlock');
    }
    if (this.tableRowData.isSuperAdmin) {
      this.options = this.options.filter((i) => i.value !== 'grantAdmin');
    } else {
      this.options = this.options.filter((i) => i.value !== 'revokeAdmin');
    }
  }

  toggleMenu(event: MouseEvent) {
    this.isOpen = !this.isOpen;

    if (this.isOpen && this.menuElement) {
      const button = event.target as HTMLElement;
      const buttonRect = button.getBoundingClientRect();
      const menuElement = this.menuElement.nativeElement;

      const scrollableContainer = this.getScrollableContainer(button);

      // console.log(scrollableContainer);
      const containerRect = scrollableContainer.getBoundingClientRect();

      console.log(containerRect);

      const menuHeight = menuElement.offsetHeight;
      const spaceBelow = window.innerHeight - buttonRect.bottom;
      const spaceAbove = buttonRect.top;

      this.isAbove = spaceBelow < menuHeight && spaceAbove > spaceBelow;

      menuElement.style.top = this.isAbove
        ? `${buttonRect.top - containerRect.top - menuHeight}px`
        : `${buttonRect.bottom - containerRect.top}px`;
      // menuElement.style.left = `${buttonRect.left - containerRect.left}px`;
    }
  }

  getScrollableContainer(element: HTMLElement): HTMLElement {
    let parent = element.parentElement;
    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY;
      if (overflowY === 'auto' || overflowY === 'scroll') {
        return parent;
      }
      parent = parent.parentElement;
    }
    return document.body;
  }

  onOptionSelect(option: any) {
    this.optionSelected.emit(option.value);
    this.toggle.emit(); // Close the menu after selection
  }

  @HostListener('document:click', ['$event.target'])
  onClickOutside(targetElement: HTMLElement) {
    if (!targetElement.closest('.table-menu')) {
      this.isOpen = false;
    }
  }
}
