import { Component } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-button',
  standalone: true,
  imports: [SvgIconComponent],
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss',
})
export class BackButtonComponent {
  constructor(private router: Router, private location: Location) {}

  goBack(): void {
    if (window.history.length > 1) {
      this.location.back(); // Go back if there is history
    } else {
      this.router.navigate(['/']); // Navigate to fallback route
    }
  }
}
