import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from '../../components/sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from '../../components/loader/loader.component';

@Component({
  selector: 'app-private-layout',
  imports: [RouterModule, SidebarComponent, CommonModule, LoaderComponent],
  standalone: true,
  templateUrl: './private-layout.component.html',
  styleUrl: './private-layout.component.scss',
})
export class PrivateLayoutComponent {
  sidebarWidth = 100; // collapsed width
  expandedWidth = 300; // expanded width
  isPinned = false;

  onSidebarToggle(event: { expanded: boolean; pinned: boolean }) {
    this.isPinned = event.pinned;
    if (event.pinned && event.expanded) {
      this.sidebarWidth = 300; // collapsed width
    } else {
      this.sidebarWidth = 100; // expanded width
    }
  }
}
