import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Toast {
  type: 'info' | 'success' | 'warning' | 'error';
  message: string;
  action?: string;
  actionCallback?: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private toastsSubject = new BehaviorSubject<Toast[]>([]);
  toasts$ = this.toastsSubject.asObservable();

  show(
    type: 'info' | 'success' | 'warning' | 'error',
    message: string,
    action?: string,
    actionCallback?: () => void
  ) {
    const toast: Toast = { type, message, action, actionCallback };
    this.toastsSubject.next([...this.toastsSubject.getValue(), toast]);

    // Automatically dismiss the toast after 5 seconds
    setTimeout(() => this.dismiss(toast), 5000);
  }

  dismiss(toast: Toast) {
    const toasts = this.toastsSubject.getValue().filter((t) => t !== toast);
    this.toastsSubject.next(toasts);
  }

  info(message: string, action?: string, actionCallback?: () => void) {
    this.show('info', message, action, actionCallback);
  }

  success(message: string, action?: string, actionCallback?: () => void) {
    this.show('success', message, action, actionCallback);
  }

  warning(message: string, action?: string, actionCallback?: () => void) {
    this.show('warning', message, action, actionCallback);
  }

  error(message: string, action?: string, actionCallback?: () => void) {
    this.show('error', message, action, actionCallback);
  }
}
