import { Component } from '@angular/core';
import { SharedModule } from '../../modules/shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { ToasterService } from '../../services/toaster.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPasswordRequest } from '../../models/forgot-password.interface';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  forgotPasswordLoginForm: FormGroup;
  verificationEmailSent: boolean = false;
  emailAddress: string = '';
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private router: Router,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) {
    this.forgotPasswordLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    // const userEmailToReset = this.route.snapshot.paramMap.get('email');
    // const validRegex =
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // if (userEmailToReset?.match(validRegex)) {
    //   // console.log('Valid email');
    // } else {
    //   // console.log('Not valid email');
    //   this.router.navigateByUrl('/forgot-password');
    // }
  }

  submitEmail() {
    this.forgotPasswordLoginForm.markAllAsTouched();
    if (this.forgotPasswordLoginForm.valid) {
      const email = this.forgotPasswordLoginForm.get('email')?.value;
      const forgotPasswordRequest: ForgotPasswordRequest = {
        email: email,
      };
      this.loadingService.show();
      this.api.forgotPassword(forgotPasswordRequest).subscribe({
        next: (res) => {
          console.log(res);
          this.verificationEmailSent = true;
          this.emailAddress = email;
          this.loadingService.hide();
          // this.toasterService.success(res.data);
        },
        error: (err) => {
          this.verificationEmailSent = false;
          switch (err.status) {
            // case 400:
            //   break;
            default:
              this.toasterService.error(err.error.errorDesc);
              break;
          }
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
      });
    }
  }

  closeWindow() {
    window.close();
  }
}
