import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-search-box',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './search-box.component.html',
  styleUrl: './search-box.component.scss',
})
export class SearchBoxComponent {
  @Input() placeholder: string = 'Search...';
  @Input() buttonLabel: string = 'Search';
  @Output() search = new EventEmitter<string>();

  searchTerm: string = '';

  onSearch() {
    console.log('onSearch');
    this.search.emit(this.searchTerm);
  }
}
