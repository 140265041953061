<div class="toaster">
  <div
    *ngFor="let toast of toasts"
    class="toast"
    [ngClass]="toast.type"
    [@toastAnimation]
  >
    <div class="circle_frame">
      <app-svg-icon
        *ngIf="toast.type === 'info'"
        [path]="'assets/icons/info.svg'"
      ></app-svg-icon>
      <app-svg-icon
        *ngIf="toast.type === 'error'"
        [path]="'assets/icons/close.svg'"
      ></app-svg-icon>
      <app-svg-icon
        *ngIf="toast.type === 'warning'"
        [path]="'assets/icons/warning.svg'"
      ></app-svg-icon>
      <app-svg-icon
        *ngIf="toast.type === 'success'"
        [path]="'assets/icons/tick.svg'"
      ></app-svg-icon>
    </div>
    <div class="message">
      {{ toast.message || "Oops, something went wrong" }}
    </div>
    <!-- <button *ngIf="toast.action" (click)="onAction(toast)" class="action">
      {{ toast.action }}
    </button> -->
    <!-- <button (click)="dismiss(toast)" class="close">x</button> -->
  </div>
</div>
