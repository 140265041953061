import { Component } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { SharedModule } from '../../modules/shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { LoadingService } from '../../services/loading.service';
import { ResetPasswordRequest } from '../../models/reset-password.interface';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent {
  resetPasswordForm: FormGroup;
  passwordContainsLowercase = false;
  passwordContainsUppercase = false;
  passwordContainsNumber = false;
  passwordContainsSpecial = false;
  passwordMinLength = false;
  passwordInvalid = true;
  token: string = '';
  validToken: boolean = false;
  validatingToken: boolean = false;
  passwordUpdatedSuccessfully: boolean = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private loadingService: LoadingService,
    private toasterService: ToasterService
  ) {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: this.passwordsMatchValidator } as AbstractControlOptions
    );
    this.resetPasswordForm.get('newPassword')?.valueChanges.subscribe(() => {
      this.resetPasswordForm.get('confirmPassword')?.reset();
    });
  }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token') || '';
    this.validatingToken = true;
    this.loadingService.show();
    this.api.validateTokenForResetPassword(this.token).subscribe({
      next: (res) => {
        this.validToken = true;
        this.validatingToken = false;
        setTimeout(() => {
          this.loadingService.hide();
        }, 1000);
      },
      error: (err) => {
        this.validToken = false;
        this.validatingToken = false;
        console.log(err);
        setTimeout(() => {
          this.loadingService.hide();
        }, 1000);
      },
    });
  }

  validatePassword(): void {
    const password = this.resetPasswordForm.get('newPassword')?.value;
    this.passwordContainsLowercase = /[a-z]/.test(password);
    this.passwordContainsUppercase = /[A-Z]/.test(password);
    this.passwordContainsNumber = /\d/.test(password);
    this.passwordContainsSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    this.passwordMinLength = password.length >= 8;
    this.passwordInvalid = !(
      this.passwordContainsLowercase &&
      this.passwordContainsUppercase &&
      this.passwordContainsNumber &&
      this.passwordContainsSpecial &&
      this.passwordMinLength
    );
  }

  passwordsMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get('newPassword')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  requestResetPassword() {
    this.resetPasswordForm.markAllAsTouched();
    if (!this.passwordInvalid && this.resetPasswordForm.valid) {
      const resetPasswordRes: ResetPasswordRequest = {
        password: this.resetPasswordForm.get('newPassword')?.value,
        confirmPassword: this.resetPasswordForm.get('confirmPassword')?.value,
      };
      this.loadingService.show();
      this.api.resetPassword(resetPasswordRes, this.token).subscribe({
        next: () => {
          this.passwordUpdatedSuccessfully = true;
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
        error: (err) => {
          this.passwordUpdatedSuccessfully = false;
          this.toasterService.error(err.error.errorDesc);
          setTimeout(() => {
            this.loadingService.hide();
          }, 1000);
        },
      });
    }
  }

  goToForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
}
