import { Component, ViewContainerRef } from '@angular/core';
import { TableBoxComponent } from '../../table-box/table-box.component';
import { ApiService } from '../../../services/api.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { AdvancedFilterComponent } from '../../advanced-filter/advanced-filter.component';
import { ModalComponent } from '../../modal/modal.component';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { TableMenuComponent } from '../../table-menu/table-menu.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { FormsModule } from '@angular/forms';
import { User } from '../../../models/user.interface';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ToasterService } from '../../../services/toaster.service';
import {
  UserStatsData,
  UserStatsResponse,
} from '../../../models/user-stats-response.interface';
import { LoadingService } from '../../../services/loading.service';
import { NoDataFoundComponent } from '../../no-data-found/no-data-found.component';
import { NoDataComponent } from '../../no-data/no-data.component';
import { HighlightPipe } from '../../../pipes/highlight.pipe';
import { PaginatedListDataResponse } from '../../../models/paginated-list-data-response';
import { TableListRequest } from '../../../models/table-list-request.interface';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [
    CommonModule,
    TableBoxComponent,
    SvgIconComponent,
    DropdownComponent,
    TooltipDirective,
    TableMenuComponent,
    UserDialogComponent,
    FormsModule,
    NoDataFoundComponent,
    NoDataComponent,
    HighlightPipe,
  ],
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss',
})
export class UserManagementComponent {
  //table
  tableLoaded: boolean = false;
  totalTablePages = 0;
  currentTablePage = 1;
  currentPage: number = 0;
  rowsPerPage: number = 10;
  rowsPerPageOptions: number[] = [];
  focusSearch: boolean = false;
  searchTerm: string = '';
  previousTerm: string = '';
  selectedItems: User[] = [];
  // deSelectedItems: User[] = [];
  sortColumn: string = 'createdDate';
  sortDirection: 'asc' | 'desc' = 'desc';
  allTableRecordsSelected: boolean = false;
  tableStats: UserStatsData = {
    total_users: 0,
    active_users: 0,
    inactive_users: 0,
  };
  tableListData: PaginatedListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  tableListRequest: TableListRequest = {
    selectFields: [
      'id',
      'name',
      'email',
      'username',
      'mobile',
      'loginMode',
      'isActive',
      'isLocked',
      'isSuperAdmin',
      'roles',
      'createdDate',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: this.rowsPerPage,
    page: this.currentPage,
  };

  // table menu
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Mark as active', value: 'active' },
    { label: 'Mark as inactive', value: 'inactive' },
    { label: 'Lock User', value: 'lock' },
    { label: 'Unlock User', value: 'unlock' },
    { label: 'Grant Admin Access', value: 'grantAdmin' },
    { label: 'Revoke Admin Access', value: 'revokeAdmin' },
    {label: 'Send Welcome Mail',value: 'welcomeMail'}
  ];

  // filter
  filterFields = [
    {
      displayName: 'Name',
      key: 'name',
    },
    {
      displayName: 'Email',
      key: 'email',
    },
    {
      displayName: 'Phone',
      key: 'mobile',
    },
    // {
    //   displayName: 'Status',
    //   key: 'status',
    //   options: [
    //     {
    //       displayName: 'Active',
    //       key: true,
    //     },
    //     {
    //       displayName: 'Inactive',
    //       key: false,
    //     },
    //   ],
    // },
    // {
    //   displayName: 'Locked',
    //   key: 'locked',
    //   options: [
    //     {
    //       displayName: 'Yes',
    //       key: true,
    //     },
    //     {
    //       displayName: 'No',
    //       key: false,
    //     },
    //   ],
    // },
    // {
    //   displayName: 'Admin',
    //   key: 'admin',
    //   options: [
    //     {
    //       displayName: 'Yes',
    //       key: true,
    //     },
    //     {
    //       displayName: 'No',
    //       key: false,
    //     },
    //   ],
    // },
  ];
  isFilterApplied: boolean = false;

  constructor(
    private api: ApiService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef,
    private loadingService: LoadingService
  ) {}

  // init
  ngOnInit() {
    this.tableLoaded = false;
    this.fetchAllData();
  }

  // pagination helper methods
  patchTableListWithSerialNumbers() {
    this.tableListData.records?.forEach((record: User, index: number) => {
      record.slNo = (this.currentTablePage - 1) * this.rowsPerPage + index + 1;
    });
  }

  calculateTotalPages() {
    if (this.tableListRequest.where) {
      this.totalTablePages = Math.ceil(
        this.tableListData.totalCount / this.rowsPerPage
      );
    } else {
      this.totalTablePages = Math.ceil(
        this.tableStats.total_users / this.rowsPerPage
      );
    }
  }

  generateRowsPerPageOptions(totalRecords: number): void {
    const baseOptions = [10];
    const dynamicOptions = [];

    if (totalRecords > 10 && totalRecords <= 25) {
      dynamicOptions.push(25);
    }

    if (totalRecords > 25 && totalRecords <= 50) {
      dynamicOptions.push(25, 50);
    }

    // If totalRecords is between 1 and 100, add 100 to the options
    if (totalRecords > 50 && totalRecords <= 100) {
      dynamicOptions.push(50, 100);
    }

    // If totalRecords is between 101 and 1000, add 150, 250, 500, 1000
    if (totalRecords > 100 && totalRecords <= 1000) {
      dynamicOptions.push(100, 150, 250, 500, 1000);
    }

    // If totalRecords is greater than 1000, add multiples of 500
    if (totalRecords > 1000) {
      dynamicOptions.push(100);
      let nextOption = 150;

      // Add options starting from 150, 250, 500, 1000
      while (nextOption <= 1000) {
        dynamicOptions.push(nextOption);
        nextOption += nextOption === 150 ? 100 : 250; // add 100, then 250 up to 1000
      }

      // After 1000, add multiples of 500
      nextOption = 1500;
      while (nextOption <= totalRecords) {
        dynamicOptions.push(nextOption);
        nextOption += 500;
      }
    }

    this.rowsPerPageOptions = [...new Set([...baseOptions, ...dynamicOptions])];
  }

  updateRowsPerPage(newRowsPerPage: number) {
    this.rowsPerPage = newRowsPerPage;
    this.tableListRequest.limit = newRowsPerPage;
    this.tableListRequest.page = 0;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.getTableList();
  }

  goToPage(page: number) {
    this.currentTablePage = page;
    this.tableListRequest.page = this.currentTablePage - 1;
    this.getTableList();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.getTableList();
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.tableListRequest.page = this.currentTablePage - 1;
      this.getTableList();
    }
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.tableListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection,
    };
    this.refreshTableList();
  }

  filter() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Advanced Filter';
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );
    filterDialogRef.setInput('filterFields', this.filterFields);
    if (this.tableListRequest.where) {
      filterDialogRef.setInput('appliedFilters', this.tableListRequest.where);
    }
    modalRef.instance.close.subscribe((res: any) => {
      this.clearSelection();
      if (res && res.resetAll) {
        delete this.tableListRequest.where;
        this.isFilterApplied = false;
        this.refreshTableList();
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.tableListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression,
          };
          this.isFilterApplied = true;
        } else {
          console.error('Invalid filter !');
          delete this.tableListRequest.where;
          this.isFilterApplied = false;
        }
        this.refreshTableList();
      }
      modalRef.destroy();
    });
  }

  // single / multi selection helper methods
  isSelected(item: User): boolean {
    if (this.allTableRecordsSelected) {
      return true;
    } else {
      const recordIdx = this.selectedItems.findIndex((x) => x.id === item.id);
      if (recordIdx !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  isAllRecordsOfCurrentPageSelected(): boolean {
    return (
      this.allTableRecordsSelected ||
      (this.tableListData.records?.length > 0 &&
        this.tableListData.records?.every((item: User) =>
          this.isSelected(item)
        ))
    );
  }

  selectAllTableRecords() {
    this.allTableRecordsSelected = true;
    this.selectedItems = [];
  }

  clearSelection() {
    // console.warn('clearing both selectedItems & selectedItems !');
    this.selectedItems = [];
    // this.deSelectedItems = [];
  }

  toggleAllRecordsOfCurrentPageSelections(isChecked: boolean) {
    if (isChecked) {
      this.tableListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.tableListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
      });
    }
  }

  toggleSelection(item: User) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i.id !== item.id);
    }
  }

  // fetch table data and stats
  fetchAllData(): void {
    this.loadingService.show();
    const getTableStatsApiCall = this.api.getUsersStats();
    const getTableListApiCall = this.api.getUsers(this.tableListRequest);
    forkJoin([getTableStatsApiCall, getTableListApiCall]).subscribe({
      next: ([tableStats, tableList]) => {
        this.tableStats = tableStats.data;
        this.tableListData = tableList.data;
        this.patchTableListWithSerialNumbers();
        this.calculateTotalPages();
        this.generateRowsPerPageOptions(tableList.data.totalCount);
        this.tableLoaded = true;
        this.loadingService.hide();
      },
      error: (err) => {
        console.error(err);
        // this.toasterService.error(err.message);
        this.loadingService.hide();
      },
    });
  }

  handleTableSearch(event: KeyboardEvent) {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length > 0) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [searchTerm],
          },
          {
            name: 'email',
            alias: 'B',
            operator: 'CONTAINS',
            value: [searchTerm],
          },
        ],
        expression: 'A OR B',
      };
      // Search when Enter is pressed
      if (event.key === 'Enter') {
        this.searchTableList();
      }
    }
    // Call reset only when the search bar is cleared (avoid on multiple backspaces)
    if (searchTerm.length === 0 && this.previousTerm.length !== 0) {
      delete this.tableListRequest.where;
      this.searchTableList();
    }
    this.previousTerm = searchTerm;
  }

  tableSearch() {
    const searchTerm = this.searchTerm.trim();
    if (searchTerm.length > 0) {
      this.tableListRequest.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [this.searchTerm],
          },
          {
            name: 'email',
            alias: 'B',
            operator: 'CONTAINS',
            value: [this.searchTerm],
          },
        ],
        expression: 'A OR B',
      };
      this.searchTableList();
    }
  }

  handleMenuToggle(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? null : index;
  }

  handleOptionSelected(user: User, actionName: string) {
    if (actionName === 'edit') {
      // no confirmation is required
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = 'Edit User';
      const userDialogRef = modalRef.instance.loadComponent(
        UserDialogComponent,
        user
      );
      userDialogRef.setInput('userEditingEnabled', true);
      userDialogRef.setInput('userId', user.id);
      userDialogRef.setInput('userObj', user);
      modalRef.instance.close.subscribe((res: any) => {
        if (res && res.refresh) {
          this.refreshTableListWithStats();
        }
        modalRef.destroy();
      });
    } else {
      // need to confirm before executing the action
      let actionTitle = 'Confirmation';
      let message = 'Are you sure ?';
      let successMsg = 'Done !';
      let flag = false;
      const userId = user.id;

      switch (actionName) {
        case 'active':
        case 'inactive':
          if (actionName === 'active') {
            flag = true;
          }
          actionTitle = `${flag ? 'Activate' : 'Inactivate'} User Confirmation`;
          message = `Are you sure to ${flag ? 'activate' : 'inactivate'} ${
            user.name
          }'s account ?`;
          successMsg = `Successfully ${flag ? 'activated' : 'inactived'} ${
            user.name
          }'s account`;
          break;
        case 'lock':
        case 'unlock':
          if (actionName === 'lock') {
            flag = true;
          }
          actionTitle = `${flag ? 'Lock' : 'Unlock'} User Account Confirmation`;
          message = `Are you sure to ${flag ? 'lock' : 'unlock'} ${
            user.name
          }'s account ?`;
          successMsg = `Successfully ${flag ? 'locked' : 'unlocked'} ${
            user.name
          }'s account`;
          break;
        case 'grantAdmin':
        case 'revokeAdmin':
          if (actionName === 'grantAdmin') {
            flag = true;
          }
          actionTitle = `${
            flag ? 'Grant' : 'Revoke'
          } Admin Access Confirmation`;
          message = `Are you sure to ${
            flag ? 'grant' : 'revoke'
          } admin access ${flag ? 'to' : 'from'} ${user.name}'s account ?`;
          successMsg = `Successfully ${
            flag ? 'granted' : 'revoked'
          } admin access ${flag ? 'to' : 'from'} ${user.name}'s account`;
          break;
          case 'welcomeMail':
            actionTitle = `Welcome mail Confirmation`;
            message = `Are you sure to send welcome mail to ${user.username} ?`;
            successMsg = `Successfully sent welcome mail to ${user.username}`;
            break;
        default:
          console.error('unknown actionName');
          break;
      }
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = actionTitle;
      const confirmDialogRef = modalRef.instance.loadComponent(
        ConfirmDialogComponent
      );
      confirmDialogRef.instance.message = message;
      confirmDialogRef.instance.confirm.subscribe(() => {
        this.menuAction(actionName, successMsg, flag, userId!);
        modalRef.destroy();
      });
      confirmDialogRef.instance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.instance.close.subscribe(() => modalRef.destroy());
    }
  }

  menuAction(
    actionName: string,
    successMsg: string,
    flag: boolean,
    userId: string
  ) {
    switch (actionName) {
      case 'active':
      case 'inactive':
        this.api.setUserActiveStatus(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'lock':
      case 'unlock':
        this.api.setUserLock(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshTableList();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;

      case 'grantAdmin':
      case 'revokeAdmin':
        this.api.setAdminAccessStatus(flag, userId).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshTableList();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
        case 'welcomeMail':
          this.api.sendWelcomeMail(userId).subscribe({
            next: () => {
              this.toasterService.success(successMsg);
            },
            error: (err) => {
              console.error(err);
              this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
            },
          });
          break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  bulkActionConfirmation(actionName: string) {
    let actionTitle = 'Confirmation';
    let message = 'Are you sure ?';
    let successMsg = 'Done !';
    const selectedCount = this.allTableRecordsSelected
      ? this.tableStats.total_users
      : this.selectedItems.length;
    switch (actionName) {
      case 'setActive':
        actionTitle = `Activate ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? 'Users'
            : 'User'
        } Account Confirmation`;
        message = `Are you sure to activate ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? ` the selected ${selectedCount} users account?`
            : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully activated ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? `${selectedCount} users accounts`
            : `${this.selectedItems[0].name}'s account`
        }`;
        break;
      case 'setInActive':
        actionTitle = `Inactivate ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? 'Users'
            : 'User'
        } Account Confirmation`;
        message = `Are you sure to inactivate ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? ` the selected ${selectedCount} users account?`
            : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully inactivated ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? `${selectedCount} users accounts`
            : `${this.selectedItems[0].name}'s account`
        }`;
        break;
      case 'unlock':
        actionTitle = `Unlock ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? 'Users'
            : 'User'
        } Account Confirmation`;
        message = `Are you sure to unlock ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? ` the selected ${selectedCount} users account?`
            : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully unlocked ${
          this.selectedItems.length > 1 || this.allTableRecordsSelected
            ? `${selectedCount} users accounts`
            : `${this.selectedItems[0].name}'s account`
        }`;
        break;
      default:
        console.error('unknown actionName');
        break;
    }
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = actionTitle;
    const confirmDialogRef = modalRef.instance.loadComponent(
      ConfirmDialogComponent
    );
    confirmDialogRef.instance.message = message;
    confirmDialogRef.instance.confirm.subscribe(() => {
      this.bulkAction(actionName, successMsg);
      modalRef.destroy();
    });
    confirmDialogRef.instance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.instance.close.subscribe(() => modalRef.destroy());
  }

  bulkAction(actionName: string, successMsg: string) {
    const selectedIds = this.selectedItems.map((user: User) => {
      return user.id!;
    });
    switch (actionName) {
      case 'setActive':
        this.api.setUserActiveStatus(true, selectedIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'setInActive':
        this.api.setUserActiveStatus(false, selectedIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshTableListWithStats();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'unlock':
        this.api.setUserLock(false, selectedIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            this.refreshTableList();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  getTableStats() {
    this.api.getUsersStats().subscribe({
      next: (res: UserStatsResponse) => {
        this.tableStats = res.data;
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  getTableList() {
    this.api.getUsers(this.tableListRequest).subscribe({
      next: (res) => {
        this.tableListData = res.data;
        this.patchTableListWithSerialNumbers();
        this.calculateTotalPages();
        this.generateRowsPerPageOptions(res.data.totalCount);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  searchTableList() {
    this.api.getUsers(this.tableListRequest).subscribe({
      next: (res) => {
        this.currentTablePage = 1; // Reset to first page
        this.currentPage = 0;
        this.tableListData = res.data;
        this.patchTableListWithSerialNumbers();
        this.calculateTotalPages();
        this.generateRowsPerPageOptions(res.data.totalCount);
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  clearSearchQuery() {
    this.searchTerm = '';
  }

  refreshTableList() {
    this.getTableList();
  }

  refreshTableListWithStats() {
    this.getTableList();
    this.getTableStats();
  }

  addNewRecord() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Create New User';
    const userDialogRef = modalRef.instance.loadComponent(UserDialogComponent);
    modalRef.instance.close.subscribe((res: any) => {
      if (res && res.refresh) {
        this.refreshTableListWithStats();
      }
      modalRef.destroy();
    });
  }
}
