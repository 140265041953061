import { ScrollingModule } from '@angular/cdk/scrolling';
import { ApiService } from '../../services/api.service';
import { SharedModule } from '../../modules/shared/shared.module';
import { MaterialModule } from '../../modules/material/material.module';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from '@angular/core';
import { of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';
import {
  FormControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  NgLabelTemplateDirective,
  NgOptionTemplateDirective,
  NgSelectComponent,
} from '@ng-select/ng-select';
import { TableListRequest } from '../../models/table-list-request.interface';
import { OrderBy } from '../../models/user-list-request.interface';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { HighlightPipe } from '../../pipes/highlight.pipe';

@Component({
  selector: 'app-autocomplete-dropdown',
  standalone: true,
  imports: [
    SharedModule,
    ScrollingModule,
    MaterialModule,
    NgLabelTemplateDirective,
    NgOptionTemplateDirective,
    NgSelectComponent,
    SvgIconComponent,
    ClickOutsideDirective,
    HighlightPipe,
  ],
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrl: './autocomplete-dropdown.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteDropdownComponent),
      multi: true,
    },
  ],
})
export class AutocompleteDropdownComponent implements ControlValueAccessor {
  @Input() apiUrl: string = '';
  @Input() selectFields: string[] = [];
  @Input() limit: number = 10;
  @Input() orderBy: OrderBy = {};
  @Input() filterByKey: string = '';
  @Input() placeholder: string = '';
  @Input() formControl!: FormControl;
  @Input() bindLabel: string = '';
  @Input() bindValue: string = '';
  @Input() skipIds: string[] = [];
  @Input() disable: boolean = false;
  @Output() suggestionSelected = new EventEmitter<any>();

  value: any;
  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  isDisabled: boolean = false;
  searchTerm: string = '';
  previousTerm: string = '';
  focusSearch: boolean = false;
  suggestions: any[] = [];
  showSuggestions = false;
  page = 0;
  canPullMoreData: boolean = false;
  loading = false;
  searchTermSubject: Subject<string> = new Subject<string>();
  searchListRequest: TableListRequest = {
    selectFields: [],
    orderBy: this.orderBy,
    limit: this.limit | 10,
    page: this.page | 0,
  };
  maxVisibleItems: number = this.limit | 10;

  constructor(private apiService: ApiService) {}

  // Set initial value from form control
  writeValue(value: any): void {
    if (value) {
      this.value = value;
      this.searchTerm = value;
    } else {
      this.value = null;
      this.searchTerm = '';
    }
  }

  // When value changes in the form control
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // On blur or touch event
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // If the form control is disabled
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  fetchSuggestions() {
    this.loading = true;
    const payload = this.getRequestPayload();
    this.apiService.getSuggestions(this.apiUrl, payload).subscribe({
      next: (res) => {
        const newRecords = res.data.records || [];
        if (this.page === 0) {
          this.suggestions = newRecords; // Initial load
        } else {
          this.suggestions = [...this.suggestions, ...newRecords]; // Append to existing data
        }
        if (newRecords.length < this.limit || newRecords.length === 0) {
          this.canPullMoreData = false;
        } else {
          this.canPullMoreData = true;
        }
        this.loading = false;
        this.showSuggestions = true;
      },
      error: (err) => {
        console.error('Error fetching suggestions:', err);
        this.loading = false;
      },
    });
  }

  getRequestPayload(): TableListRequest {
    const payload: TableListRequest = {
      selectFields: this.selectFields,
      orderBy: this.orderBy,
      limit: this.limit,
      page: this.page,
    };
    if (this.skipIds.length > 0) {
      payload.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [this.searchTerm],
          },
          {
            name: 'code',
            alias: 'B',
            operator: 'CONTAINS',
            value: [this.searchTerm],
          },
          {
            name: 'id',
            alias: 'C',
            operator: 'NE',
            value: this.skipIds,
          },
        ],
        expression: '(A OR B) AND C',
      };
    } else {
      payload.where = {
        filterConditions: [
          {
            name: 'name',
            alias: 'A',
            operator: 'CONTAINS',
            value: [this.searchTerm],
          },
          {
            name: 'code',
            alias: 'B',
            operator: 'CONTAINS',
            value: [this.searchTerm],
          },
        ],
        expression: 'A OR B',
      };
    }
    return payload;
  }

  ngOnInit() {
    // filter out skipIds list if it has null, undefined and empty string
    this.skipIds = this.skipIds.filter(
      (item) => item !== null && item !== undefined && item !== ''
    );
    this.searchTermSubject
      .pipe(
        debounceTime(300), // Wait for 300ms pause in typing
        distinctUntilChanged(), // Only continue if the search term changed
        switchMap(() => {
          // Cancel previous API call and start a new one
          this.loading = true;
          return this.apiService
            .getSuggestions(this.apiUrl, this.getRequestPayload())
            .pipe(
              catchError((err) => {
                console.error('Error fetching suggestions:', err);
                this.loading = false;
                return of([]); // Return an empty array if an error occurs
              })
            );
        })
      )
      .subscribe((res: any) => {
        const newRecords = res.data.records || [];
        if (this.page === 0) {
          this.suggestions = newRecords; // Initial load
        } else {
          this.suggestions = [...this.suggestions, ...newRecords]; // Append to existing data
        }
        this.canPullMoreData = newRecords.length >= this.limit;
        this.loading = false;
        this.showSuggestions = true;
      });
  }

  // Handle lazy loading / pagination on scroll
  onScroll(event: any) {
    const element = event.target;
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight &&
      this.canPullMoreData
    ) {
      this.page++;
      this.fetchSuggestions();
    }
  }

  // calculateViewportHeight(resultCount: number): number {
  //   const itemHeight = 50; // Height of each result item in pixels
  //   const visibleItems = Math.min(resultCount, this.maxVisibleItems); // Calculate the number of visible items
  //   return visibleItems * itemHeight; // Set the viewport height dynamically
  // }

  // onScroll(): void {
  //   // Check if we can load more data and the loading is not already in progress
  //   if (this.canPullMoreData && !this.loading) {
  //     this.page++; // Increase the page number
  //     this.fetchSuggestions(); // Fetch more suggestions
  //   }
  // }

  updateSearchTerm() {
    const searchTerm = this.searchTerm;
    this.page = 0;
    this.searchTermSubject.next(searchTerm);
  }

  handleEnter(event: KeyboardEvent) {
    if (this.searchTerm === '  ' && event.key === 'Enter') {
      this.searchTerm = ''; // reset searchTerm
      this.page = 0;
      this.fetchSuggestions();
    }
  }

  select(result: any) {
    this.searchTerm = result[this.bindLabel].trim() || '';
    this.handleSelectionChange(result);
  }

  // Handle the selection change in the autocomplete
  handleSelectionChange(selectedValue: any): void {
    this.value = selectedValue[this.bindValue];
    this.onChange(this.value);
    this.onTouched();
    this.showSuggestions = false;
    this.suggestionSelected.emit(this.value);
  }

  onInputFocus() {
    this.focusSearch = true;
    // if (this.suggestions.length > 0) {
    //   this.suggestions = [];
    //   this.updateSearchTerm();
    // }
  }

  onInputFocusOut() {
    this.focusSearch = false;
    // console.log(this.value);
    // console.log(this.searchTerm);
    // if (!this.value || !this.searchTerm) {
    //   // If there's no valid selection, reset the input field
    //   this.searchTerm = '';
    // }
  }
}
