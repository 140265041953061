<div class="under_progress_component_container">
  <div class="header">
    {{ header ? header : "Under Development !" }}
  </div>

  <div class="cs_page_cover">
    <img src="assets/images/coming_soon.svg" alt="Not Found" />
    <p class="title">Get Ready for Lift-Off! This feature is coming soon !</p>
    <p class="punch_line">
      Our rocket is fueling up with some exciting new features, and we can't
      wait to launch them your way.
    </p>
  </div>
</div>
