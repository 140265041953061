import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Import shared components, directives, and pipes here
import { InputComponent } from '../../components/input/input.component';
import { SearchBoxComponent } from '../../components/search-box/search-box.component';
import { TableBoxComponent } from '../../components/table-box/table-box.component';
import { SelectComponent } from '../../components/select/select.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputComponent,
    SearchBoxComponent,
    TableBoxComponent,
    SelectComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputComponent, // Export shared components so they can be used in standalone components
    SearchBoxComponent,
    TableBoxComponent,
    SelectComponent,
  ],
})
export class SharedModule {}
