<div
  class="sidebar"
  [class.expanded]="isExpanded"
  [class.collapsed]="!isExpanded"
  (mouseenter)="expandSidebar()"
  (mouseleave)="collapseSidebar()"
>
  <div class="top_section">
    <div
      class="logo-with-pin"
      [ngClass]="{ centered_flex_container: !isExpanded }"
    >
      <div class="logo">
        <img
          [hidden]="!isExpanded"
          src="assets/images/smarttos-logo.svg"
          alt="Logo"
        />
        <img
          [hidden]="isExpanded"
          src="assets/images/smarttos-logo-small.svg"
          alt="Logo"
        />
      </div>

      <div
        [hidden]="!isExpanded"
        class="pin-button"
        (click)="togglePin($event)"
        [title]="isPinned ? 'Unpin Sidebar' : 'Pin Sidebar'"
      >
        <!-- <app-svg-icon
          *ngIf="!isPinned"
          [path]="'assets/icons/pin-circle.svg'"
        ></app-svg-icon>
        <app-svg-icon
          *ngIf="isPinned"
          [path]="'assets/icons/pinned-circle.svg'"
        ></app-svg-icon> -->

        <app-svg-icon [path]="'assets/icons/sb.svg'"></app-svg-icon>
      </div>
    </div>
    <div class="search">
      @if(isExpanded){
      <input
        type="text"
        placeholder="Search"
        [(ngModel)]="searchQuery"
        (input)="filterMenuItems()"
        id="search_menu"
        autocomplete="off"
      />
      }@else {
      <div class="collapsed_search_btn centered_flex_container">
        <app-svg-icon
          class="centered_flex_container"
          [path]="'assets/icons/search_icon.svg'"
        ></app-svg-icon>
      </div>
      }
    </div>

    <nav class="menu">
      <ul class="first_level" *ngFor="let menu of filteredMenuItems">
        <li
          [class.expanded_menu]="isOpen(menu.id)"
          [class.active]="isActiveParent(menu.id)"
        >
          <a class="primary-menu" (click)="toggleMenu(menu.id)">
            <div class="left_section">
              <app-svg-icon [path]="menu.icon"></app-svg-icon>
              <span *ngIf="isExpanded">{{ menu.label }}</span>
            </div>
            <div class="right_section" *ngIf="isExpanded">
              <app-svg-icon
                *ngIf="isOpen(menu.id)"
                [path]="'assets/icons/chevron_up.svg'"
              ></app-svg-icon>
              <app-svg-icon
                *ngIf="!isOpen(menu.id)"
                [path]="'assets/icons/chevron_down.svg'"
              ></app-svg-icon>
            </div>
          </a>
          <ul
            class="second_level"
            [ngClass]="{ open: isOpen(menu.id) }"
            *ngIf="isExpanded"
          >
            <li
              *ngFor="let child of menu.children"
              [class.active]="isActiveChild(child.link)"
            >
              <a
                class="secondary-menu"
                [routerLink]="child.link"
                routerLinkActive="router-link-active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <div class="left_section">{{ child.label }}</div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>

  <div class="bottom_section">
    <ul class="first_level">
      <li *ngFor="let item of sideBarFixedItems">
        <a
          [routerLink]="[item.mainRoute]"
          (click)="toggleMenu(item)"
          [class.active]="isActive(item.mainRoute)"
          class="primary-menu"
        >
          <div class="left_section">
            <div class="display-contents" [ngSwitch]="item.displayName">
              <app-svg-icon
                *ngSwitchCase="'Support'"
                [path]="'assets/icons/support_menu_icon.svg'"
              ></app-svg-icon>

              <app-svg-icon
                *ngSwitchCase="'Settings'"
                [path]="'assets/icons/settings_menu_icon.svg'"
              ></app-svg-icon>

              <div class="display-contents" *ngSwitchDefault>
                <app-svg-icon
                  [path]="'assets/icons/warning.svg'"
                ></app-svg-icon>
              </div>
            </div>
            <span [hidden]="!isExpanded">
              {{ item.displayName }}
            </span>
          </div>

          <div class="right_section">
            <ng-container *ngIf="item.subMenuItems.length > 0">
              <app-svg-icon
                [hidden]="item.open"
                [path]="'assets/icons/chevron_up.svg'"
              ></app-svg-icon>
              <app-svg-icon
                [hidden]="!item.open"
                [path]="'assets/icons/chevron_down.svg'"
              ></app-svg-icon>
            </ng-container>
          </div>
        </a>
      </li>
    </ul>

    <div class="profile_button">
      <div
        class="left"
        [routerLink]="['/user-profile']"
        title="Click to view profile"
      >
        <img class="avatar" src="assets/images/avatar.svg" alt="avatar" />
        <div class="profile_details" [hidden]="!isExpanded">
          <div class="name">{{ userProfile?.name }}</div>
          <div class="username">{{ userProfile?.username }}</div>
        </div>
      </div>
      <div class="logout_btn" (click)="logout()" *ngIf="isExpanded">
        <app-svg-icon [path]="'assets/icons/logout_icon.svg'"></app-svg-icon>
      </div>
    </div>
  </div>
</div>
