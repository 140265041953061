<div class="container">
  <div class="header">Customer Master</div>

  <div class="container-content">
    <app-customer-master
      [customerStats]="customerStats"
      [userListData]="customerListData"
      (getUserStats)="getCustomerStats()"
      (getUsersList)="getCustomersList()"
      (refreshUserList)="refreshUserList()"
      (refreshUsersListWithStats)="refreshUsersListWithStats()"
      (sortUsersList)="sortUsersList($event)"
      (filterUsersList)="filterUsersList($event)"
      (searchUsersList)="searchUsersList($event)"
      (resetSearch)="resetSearch()"
    >
    </app-customer-master>
  </div>
</div>
