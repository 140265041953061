import {
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { BackButtonComponent } from '../../../back-button/back-button.component';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControlOptions,
  FormArray,
  ValidatorFn,
  AbstractControl,
  FormControl,
} from '@angular/forms';
import { User } from '../../../../models/user.interface';
import { ApiService } from '../../../../services/api.service';
import { LoadingService } from '../../../../services/loading.service';
import { ToasterService } from '../../../../services/toaster.service';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { atLeastOneCheckboxCheckedValidator } from '../../../../validators/atleastOneCheckboxCheked';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '../../../../models/customer-master.interface';

@Component({
  selector: 'app-manage-customer',
  standalone: true,
  imports: [SharedModule, SvgIconComponent, BackButtonComponent],
  templateUrl: './manage-customer.component.html',
  styleUrl: './manage-customer.component.scss',
})
export class ManageCustomerComponent {
  @Input() customerEditingEnabled: boolean = false;
  @Input() customerId!: string;
  @Input() customerObj!: Customer;
  readOnlyMode: boolean = true;

  customerForm: FormGroup;
  customerTypes = [
    { name: 'Freight Forwarder', value: 'FREIGHT_FORWARDER', checked: false },
    { name: 'Consignee', value: 'CONSIGNEE', checked: false },
    { name: 'Line Agent', value: 'LINE_AGENT', checked: false },
    { name: 'Line', value: 'LINE', checked: false },
    { name: 'CHA', value: 'CHA', checked: false },
    { name: 'Transporter', value: 'TRANSPORTER', checked: false },
    { name: 'Shipper', value: 'SHIPPER', checked: false },
    { name: 'Group Company', value: 'GROUP_COMPANY', checked: false },
    { name: 'Other CFS', value: 'OTHER_CFS', checked: false },
  ];

  // Query all form control elements with #formField
  @ViewChildren('formField') formFields!: QueryList<ElementRef>;

  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private toasterService: ToasterService,
    private loadingService: LoadingService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.customerForm = this.fb.group({
      code: ['', Validators.required],
      parentCustomerId: [''],
      name: ['', Validators.required],
      sisterCustomerId: [''],
      nominated: [false],
      customerType: this.fb.array(
        this.customerTypes.map((ct) => new FormControl(ct.checked)),
        atLeastOneCheckboxCheckedValidator()
      ),
      contactInformation: this.fb.array([this.createContactFormGroup()]),
      responsibleMExecUsedId: [''],
      serviceTaxNo: [''],
      tdsExemptedFlag: [false],
      tdsExemptionNo: [{ value: '', disabled: true }],
      tdsPercentage: [0],
      incomeTaxNo: [''],
      creditNoOfDays: [0],
      creditAmount: [0],
      economicFreeZoneFlag: [false],
      efzCertificateNo: [{ value: '', disabled: true }],
      tanNumber: [''],
      importerExporterCode: [''],
      pdaCreditAmount: [{ value: 0, disabled: true }],
      active: [true],
      pdaMaintain: [false],
      // createdBy: [''],
      // modifiedBy: [''],
      // createdDate: [''],
      // modifiedDate: [''],
    });
  }

  ngOnInit() {
    this.customerId = this.route.snapshot.paramMap.get('customerId') || '';
    if (this.customerId !== '') {
      if (this.route.snapshot.url[0].path === 'edit') {
        this.customerEditingEnabled = true;
        this.readOnlyMode = false;
      } else {
        this.customerEditingEnabled = false;
        this.readOnlyMode = true;
        this.customerForm.disable();
      }
      this.loadingService.show();
      this.api.getCustomer(this.customerId).subscribe({
        next: (res: any) => {
          const customerData: Customer = res.data;
          this.customerForm.patchValue(customerData);
          this.hydrateForm();
          this.loadingService.hide();
        },
        error: (err) => {
          console.log(err);
          this.loadingService.hide();
        },
      });
    } else {
      this.readOnlyMode = false;
      this.customerEditingEnabled = false;
      this.hydrateForm();
    }
  }

  hydrateForm() {
    this.customerForm
      .get('tdsExemptedFlag')
      ?.valueChanges.subscribe((checked) => {
        const inputControl = this.customerForm.get('tdsExemptionNo');
        if (checked === 'true') {
          inputControl?.enable();
        } else {
          this.customerForm.get('tdsExemptionNo')?.reset();
          inputControl?.disable();
        }
      });

    this.customerForm
      .get('economicFreeZoneFlag')
      ?.valueChanges.subscribe((checked) => {
        const inputControl = this.customerForm.get('efzCertificateNo');
        if (checked === 'true') {
          inputControl?.enable();
        } else {
          this.customerForm.get('efzCertificateNo')?.reset();
          inputControl?.disable();
        }
      });

    this.customerForm.get('pdaMaintain')?.valueChanges.subscribe((checked) => {
      const inputControl = this.customerForm.get('pdaCreditAmount');
      if (checked === 'true') {
        inputControl?.enable();
      } else {
        this.customerForm.get('pdaCreditAmount')?.setValue(0);
        inputControl?.disable();
      }
    });
  }

  // Method to create a new contact form group
  createContactFormGroup(): FormGroup {
    return this.fb.group({
      address: [''],
      billingAddress: [''],
      mobileNumber1: [''],
      mobileNumber2: [''],
      faxNumber: [''],
      email: [''],
      pinCode: [''],
      cityId: [''],
      stateId: [''],
      countryId: [''],
      communicationPreference: ['mobile'],
      boardLine: [''],
      directLine: [''],
      gstNumber: [''],
      gstValidTill: [''],
      defaultContact: [false],
      panNumber: [''],
      sez: [false],
      lut: [false],
    });
  }

  // Getters for contact information and additional addresses form arrays
  get contactInformation(): FormArray {
    return this.customerForm.get('contactInformation') as FormArray;
  }

  get additionalAddresses(): FormArray {
    return this.customerForm.get('additionalAddresses') as FormArray;
  }

  get customerTypeArray(): FormArray {
    return this.customerForm.get('customerType') as FormArray;
  }

  // Scroll to the first invalid form control
  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.formFields.find((element) => {
      return !element.nativeElement.validity?.valid;
    });

    if (firstInvalidControl) {
      firstInvalidControl.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      // firstInvalidControl.nativeElement.focus(); // Optionally, focus the invalid element
    }
  }

  onCheckboxChange(e: any, i: number) {
    console.log('onCheckboxChange');
    this.customerTypeArray.controls[i].setValue(e.target.checked);
  }

  // Method to add a new contact form group to the array
  addContact() {
    this.contactInformation.push(this.createContactFormGroup());
  }

  // Method to remove a contact form group from the array
  removeContact(index: number) {
    this.contactInformation.removeAt(index);
  }

  // Method to add a new address
  addAddress() {
    this.additionalAddresses.push(
      this.fb.group({
        extraAddress: [''],
      })
    );
  }

  // Method to remove an address
  removeAddress(index: number) {
    this.additionalAddresses.removeAt(index);
  }

  async submitCustomerForm() {
    console.log('submitCustomerForm');
    console.log(this.customerForm.value);
    this.customerForm.markAllAsTouched();
    if (this.customerForm.valid) {
      console.log('valid form');
      const selectedCustomerTypes = this.customerForm.value.customerType
        .map((checked: boolean, i: number) =>
          checked ? this.customerTypes[i].value : null
        )
        .filter((type: any) => type !== null);
      console.log(selectedCustomerTypes);

      const customerData: Customer = {
        ...this.customerForm.value,
        customerType: selectedCustomerTypes,
      };

      if (this.customerEditingEnabled) {
        this.updateCustomer(customerData, this.customerId);
      } else {
        this.addCustomer(customerData);
      }
    } else {
      console.log('invalid form');
      this.scrollToFirstInvalidControl();
    }
  }

  addCustomer(customerData: Customer) {
    this.loadingService.show();
    console.log(customerData);
    this.api.addCustomer(customerData).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(
          `Customer ${customerData.name} has been added !`
        );
        this.loadingService.hide();
        this.goBack();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }

  updateCustomer(customerData: Customer, customerId: string) {
    this.loadingService.show();
    console.log(customerData);
    this.api.updateCustomer(customerData, customerId).subscribe({
      next: (res) => {
        console.log(res);
        this.toasterService.success(
          `Customer ${customerData.name} has been updated !`
        );
        this.loadingService.hide();
        this.goBack();
      },
      error: (err) => {
        this.toasterService.error(err.error.errorDesc);
        console.log(err);
        this.loadingService.hide();
      },
    });
  }

  goBack() {
    this.router.navigateByUrl('/manage-masters/customers');
  }
}
