import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { TableBoxComponent } from '../../table-box/table-box.component';
import { ApiService } from '../../../services/api.service';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { UserListRequest } from '../../../models/user-list-request.interface';
import { UserListDataResponse } from '../../../models/user-list-data-response';
import { AdvancedFilterComponent } from '../../advanced-filter/advanced-filter.component';
import { ModalComponent } from '../../modal/modal.component';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { TooltipDirective } from '../../../directives/tooltip.directive';
import { TableMenuComponent } from '../../table-menu/table-menu.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { FormsModule } from '@angular/forms';
import { User } from '../../../models/user.interface';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ToasterService } from '../../../services/toaster.service';
import { UserRolesPermissionsComponent } from './../user-roles-permissions/user-roles-permissions.component';
import { UserStatsData } from '../../../models/user-stats-response.interface';
import { NoDataFoundComponent } from '../../no-data-found/no-data-found.component';

@Component({
  selector: 'app-user-management',
  standalone: true,
  imports: [
    CommonModule,
    TableBoxComponent,
    SvgIconComponent,
    DropdownComponent,
    TooltipDirective,
    TableMenuComponent,
    UserDialogComponent,
    FormsModule,
    UserRolesPermissionsComponent,
    NoDataFoundComponent,
  ],
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss',
})
export class UserManagementComponent {
  @Input() userStats: UserStatsData = {
    total_users: 0,
    active_users: 0,
    inactive_users: 0,
  };
  @Input() userListData: UserListDataResponse = {
    totalCount: 0,
    page: 0,
    limit: 0,
    records: [],
  };
  // @Input() searchQuery: string = '';
  @Output() getUserStats = new EventEmitter<void>();
  @Output() getUsersList = new EventEmitter<void>();
  @Output() refreshUserList = new EventEmitter<void>();
  @Output() refreshUsersListWithStats = new EventEmitter<void>();
  @Output() sortUsersList = new EventEmitter<any>();
  @Output() filterUsersList = new EventEmitter<any>();

  // table pagination
  currentTablePage = 1;
  paginatedData: User[] = [];
  totalTablePages = 0;
  rowsPerPageOptions: number[] = [10, 20, 50, 100];
  rowsPerPage: number = 10;
  maxVisiblePages = 5;

  currentPage: number = 0;
  data: any[] = [];

  columns: string[] = [
    'name',
    'email',
    'mobile',
    'isActive',
    'isSuperAdmin',
    'isLocked',
    'createdBy',
    'createdDate',
    'modifiedBy',
    'modifiedDate',
    'roles',
  ];

  filterFields = [
    {
      displayName: 'Name',
      key: 'name',
    },
    {
      displayName: 'Email',
      key: 'email',
    },
    {
      displayName: 'Phone',
      key: 'mobile',
    },
  ];

  sortColumn: string = 'createdDate';
  sortDirection: 'asc' | 'desc' = 'desc';
  selectedTab: string = 'users'; // 'users' 'roles';
  userListRequest: UserListRequest = {
    selectFields: [
      'id',
      'name',
      'email',
      'mobile',
      'isActive',
      'isLocked',
      'isSuperAdmin',
      'roles',
      'createdDate',
    ],
    orderBy: {
      [this.sortColumn]: this.sortDirection,
    },
    limit: 100,
    page: 0,
  };
  focusSearch: boolean = false;
  openMenuIndex: number | null = null;
  menuOptions = [
    { label: 'Edit', value: 'edit' },
    { label: 'Mark as active', value: 'active' },
    { label: 'Mark as inactive', value: 'inactive' },
    { label: 'Lock User', value: 'lock' },
    { label: 'Unlock User', value: 'unlock' },
    { label: 'Grant Admin Access', value: 'grantAdmin' },
    { label: 'Revoke Admin Access', value: 'revokeAdmin' },
  ];
  selectedItems: any[] = []; // Selected items
  // searchQuery!: string;
  tableLoaded: boolean = false;
  // isFilterApplied: boolean = false;

  constructor(
    private api: ApiService,
    private toasterService: ToasterService,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    console.log('ngOnChanges !');
    this.tableLoaded = false;
    // console.log(changes['userListData']);
    if (changes['userListData'] && !changes['userListData'].firstChange) {
      console.log(this.userListData);
      this.clearSelection();
      this.calculateTotalPages();
      this.updatePaginatedData();
      this.tableLoaded = true;
    }
  }

  calculateTotalPages() {
    console.log('calculateTotalPages has been called');
    console.log(this.userListData);
    this.totalTablePages = Math.ceil(
      this.userListData.records?.length / this.rowsPerPage
    );
    console.log('this.totalTablePages : ' + this.totalTablePages);
  }

  updatePaginatedData() {
    const startIndex = (this.currentTablePage - 1) * this.rowsPerPage;
    const endIndex = startIndex + this.rowsPerPage;
    this.paginatedData = this.userListData.records?.slice(startIndex, endIndex);
    console.log(this.paginatedData);
  }

  updateRowsPerPage(newRowsPerPage: number) {
    this.rowsPerPage = newRowsPerPage;
    this.currentTablePage = 1; // Reset to first page
    this.calculateTotalPages();
    this.updatePaginatedData();
  }

  goToPage(page: number) {
    this.currentTablePage = page;
    this.updatePaginatedData();
  }

  nextPage() {
    if (this.currentTablePage < this.totalTablePages) {
      this.currentTablePage++;
      this.updatePaginatedData();
    }
  }

  previousPage() {
    if (this.currentTablePage > 1) {
      this.currentTablePage--;
      this.updatePaginatedData();
    }
  }

  getSerialNumber(index: number): number {
    return (this.currentTablePage - 1) * this.rowsPerPage + index + 1;
  }

  isSelected(item: any): boolean {
    return this.selectedItems.includes(item);
  }

  isAllSelected(): boolean {
    return (
      this.userListData.records?.length > 0 &&
      this.userListData.records?.every((item) => this.isSelected(item))
    );
  }

  selectAll() {
    this.userListData.records?.forEach((item) => {
      if (!this.isSelected(item)) {
        this.selectedItems.push(item);
      }
    });
  }

  clearSelection() {
    this.selectedItems = [];
  }

  toggleAllSelections(isChecked: boolean) {
    if (isChecked) {
      this.userListData.records?.forEach((item) => {
        if (!this.isSelected(item)) {
          this.selectedItems.push(item);
        }
      });
    } else {
      this.userListData.records?.forEach((item) => {
        this.selectedItems = this.selectedItems.filter((i) => i !== item);
      });
    }
  }

  toggleSelection(item: any) {
    if (!this.isSelected(item)) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((i) => i !== item);
    }
  }

  bulkActionConfirmation(actionName: string) {
    let actionTitle = 'Confirmation';
    let message = 'Are you sure ?';
    let successMsg = 'Done !';
    const selectedUserCount = this.selectedItems.length;
    switch (actionName) {
      case 'setActive':
        actionTitle = `Activate ${
          this.selectedItems.length > 1 ? 'User' : 'Users'
        } Account Confirmation`;
        message = `Are you sure to activate ${
          this.selectedItems.length > 1
            ? ` the selected ${selectedUserCount} users account?`
            : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully activated ${
          this.selectedItems.length > 1
            ? `${selectedUserCount} users accounts`
            : `${this.selectedItems[0].name}'s account`
        }`;
        break;
      case 'setInActive':
        actionTitle = `Inactivate ${
          this.selectedItems.length > 1 ? 'User' : 'Users'
        } Account Confirmation`;
        message = `Are you sure to inactivate ${
          this.selectedItems.length > 1
            ? ` the selected ${selectedUserCount} users account?`
            : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully inactivated ${
          this.selectedItems.length > 1
            ? `${selectedUserCount} users accounts`
            : `${this.selectedItems[0].name}'s account`
        }`;
        break;
      case 'unlock':
        actionTitle = `Unlock ${
          this.selectedItems.length > 1 ? 'User' : 'Users'
        } Account Confirmation`;
        message = `Are you sure to unlock ${
          this.selectedItems.length > 1
            ? ` the selected ${selectedUserCount} users account?`
            : `${this.selectedItems[0].name}'s account?`
        }`;
        successMsg = `Successfully unlocked ${
          this.selectedItems.length > 1
            ? `${selectedUserCount} users accounts`
            : `${this.selectedItems[0].name}'s account`
        }`;
        break;
      default:
        console.error('unknown actionName');
        break;
    }
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = actionTitle;
    const confirmDialogRef = modalRef.instance.loadComponent(
      ConfirmDialogComponent
    );
    confirmDialogRef.instance.message = message;
    confirmDialogRef.instance.confirm.subscribe(() => {
      this.bulkAction(actionName, successMsg);
      modalRef.destroy();
    });
    confirmDialogRef.instance.cancel.subscribe(() => {
      modalRef.destroy();
    });
    modalRef.instance.close.subscribe(() => modalRef.destroy());
  }

  bulkAction(actionName: string, successMsg: string) {
    const selectedUserIds = this.selectedItems.map((user: User) => {
      return user.id;
    });
    switch (actionName) {
      case 'setActive':
        this.api.setUserActiveStatus(true, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            // this.refreshUsersListWithStats.emit()
            this.refreshUsersListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'setInActive':
        this.api.setUserActiveStatus(false, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            // this.refreshUsersListWithStats.emit()
            this.refreshUsersListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'unlock':
        this.api.setUserLock(false, selectedUserIds).subscribe({
          next: () => {
            this.clearSelection();
            this.toasterService.success(successMsg);
            // this.refreshUserList.emit()
            this.refreshUserList.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }

  sortData(column: string) {
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
    this.userListRequest.orderBy = {
      [this.sortColumn]: this.sortDirection,
    };
    this.sortUsersList.emit(this.userListRequest.orderBy);
  }

  filter() {
    // console.log(this.userListRequest);
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Advanced Filter';
    const filterDialogRef = modalRef.instance.loadComponent(
      AdvancedFilterComponent
    );
    filterDialogRef.setInput('filterFields', this.filterFields);
    if (this.userListRequest.where) {
      filterDialogRef.setInput('appliedFilters', this.userListRequest.where);
    }
    modalRef.instance.close.subscribe((res: any) => {
      // console.log(res);
      if (res && res.resetAll) {
        delete this.userListRequest.where;
        this.filterUsersList.emit(this.userListRequest.where);
        // this.isFilterApplied = false;
      } else if (res && res.refresh) {
        if (
          res.filterData.expression &&
          res.filterData.filterConditions.length > 0
        ) {
          this.userListRequest.where = {
            filterConditions: res.filterData.filterConditions,
            expression: res.filterData.expression,
          };
          // this.isFilterApplied = true;
        } else {
          console.error('Invalid filter !');
          delete this.userListRequest.where;
          // this.isFilterApplied = false;
        }
        this.filterUsersList.emit(this.userListRequest.where);
      }
      modalRef.destroy();
    });
  }

  addNewUser() {
    const modalRef = this.viewContainerRef.createComponent(ModalComponent);
    modalRef.instance.title = 'Create New User';
    const userDialogRef = modalRef.instance.loadComponent(UserDialogComponent);
    modalRef.instance.close.subscribe((res: any) => {
      if (res && res.refresh) {
        this.refreshUsersListWithStats.emit();
      }
      modalRef.destroy();
    });
  }

  onOptionSelected(option: string) {
    console.log('Selected option:', option);
  }

  handleMenuToggle(index: number) {
    this.openMenuIndex = this.openMenuIndex === index ? null : index;
  }

  handleOptionSelected(user: User, actionName: string) {
    console.log(user);

    if (actionName === 'edit') {
      // no confirmation is required
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = 'Edit User';
      const userDialogRef = modalRef.instance.loadComponent(
        UserDialogComponent,
        user
      );
      userDialogRef.setInput('userEditingEnabled', true);
      userDialogRef.setInput('userId', user.id);
      userDialogRef.setInput('userObj', user);
      modalRef.instance.close.subscribe((res: any) => {
        if (res && res.refresh) {
          this.refreshUsersListWithStats.emit();
        }
        modalRef.destroy();
      });
    } else {
      // need to confirm before executing the action
      let actionTitle = 'Confirmation';
      let message = 'Are you sure ?';
      let successMsg = 'Done !';
      let flag = false;
      const userId = user.id;

      switch (actionName) {
        case 'active':
        case 'inactive':
          if (actionName === 'active') {
            flag = true;
          }
          actionTitle = `${flag ? 'Activate' : 'Inactivate'} User Confirmation`;
          message = `Are you sure to ${flag ? 'activate' : 'inactivate'} ${
            user.name
          }'s account ?`;
          successMsg = `Successfully ${flag ? 'activated' : 'inactived'} ${
            user.name
          }'s account`;
          break;
        case 'lock':
        case 'unlock':
          if (actionName === 'lock') {
            flag = true;
          }
          actionTitle = `${flag ? 'Lock' : 'Unlock'} User Account Confirmation`;
          message = `Are you sure to ${flag ? 'lock' : 'unlock'} ${
            user.name
          }'s account ?`;
          successMsg = `Successfully ${flag ? 'locked' : 'unlocked'} ${
            user.name
          }'s account`;
          break;
        case 'grantAdmin':
        case 'revokeAdmin':
          if (actionName === 'grantAdmin') {
            flag = true;
          }
          actionTitle = `${
            flag ? 'Grant' : 'Revoke'
          } Admin Access Confirmation`;
          message = `Are you sure to ${
            flag ? 'grant' : 'revoke'
          } admin access ${flag ? 'to' : 'from'} ${user.name}'s account ?`;
          successMsg = `Successfully ${
            flag ? 'granted' : 'revoked'
          } admin access ${flag ? 'to' : 'from'} ${user.name}'s account`;
          break;
        default:
          console.error('unknown actionName');
          break;
      }
      const modalRef = this.viewContainerRef.createComponent(ModalComponent);
      modalRef.instance.title = actionTitle;
      const confirmDialogRef = modalRef.instance.loadComponent(
        ConfirmDialogComponent
      );
      confirmDialogRef.instance.message = message;
      confirmDialogRef.instance.confirm.subscribe(() => {
        this.menuAction(actionName, successMsg, flag, userId);
        modalRef.destroy();
      });
      confirmDialogRef.instance.cancel.subscribe(() => {
        modalRef.destroy();
      });
      modalRef.instance.close.subscribe(() => modalRef.destroy());
    }
  }

  menuAction(
    actionName: string,
    successMsg: string,
    flag: boolean,
    userId: string
  ) {
    switch (actionName) {
      case 'active':
      case 'inactive':
        this.api.setUserActiveStatus(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshUsersListWithStats.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      case 'lock':
      case 'unlock':
        this.api.setUserLock(flag, [userId]).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshUserList.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;

      case 'grantAdmin':
      case 'revokeAdmin':
        this.api.setAdminAccessStatus(flag, userId).subscribe({
          next: () => {
            this.toasterService.success(successMsg);
            this.refreshUserList.emit();
          },
          error: (err) => {
            console.error(err);
            this.toasterService.error(err.error.errorDesc || 'Unknown Error !');
          },
        });
        break;
      default:
        console.error('unknown actionName');
        break;
    }
  }
}
