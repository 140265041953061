<!-- <div class="loader-wrapper" *ngIf="loading">
  <div class="loader">
    <div class="spinner"></div>
    <div class="spinner-text">Loading...</div>
  </div>
</div> -->

<div class="loader-wrapper" *ngIf="config.isLoading">
  <div
    class="loader"
    [ngClass]="{
      'full-loader': config.type === 'full',
      'small-loader': config.type === 'small'
    }"
  >
    <img
      class="append_animation"
      src="assets/gifs/loader.gif"
      alt="loading..."
    />
    <!-- <div class="spinner"></div> -->
    <!-- <svg viewBox="0 0 100 100">
      <defs>
        <filter id="shadow">
          <feDropShadow
            dx="0"
            dy="0"
            stdDeviation="1.5"
            flood-color="#19b5fe"
          />
        </filter>
      </defs>
      <circle
        id="spinner"
        style="
          fill: transparent;
          stroke: #19b5fe;
          stroke-width: 7px;
          stroke-linecap: round;
          filter: url(#shadow);
        "
        cx="50"
        cy="50"
        r="45"
      />
    </svg> -->
    <div class="spinner-text">Loading...</div>
    <button
      *ngIf="config.dismissible && config.type === 'small'"
      class="dismiss-btn"
      (click)="dismiss()"
    >
      Dismiss
    </button>
  </div>
</div>
