import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): boolean {
    if (this.authService.isLoggedIn()) {
      // If the user is authenticated, redirect to the dashboard page
      console.warn('Redirecting the user to User Management via Login Guard !');
      this.router.navigate(['/admin/manage-users']);
      // this.router.navigate(['/dashboard']); // Adjust this route to where authenticated users should go
      return false; // Prevent access to the login page
    }
    return true; // Allow access to the login page if not authenticated
  }
}
